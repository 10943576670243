import {css, html, LitElement, nothing, SVGTemplateResult, unsafeCSS} from "lit";
import ViewOff16 from '@carbon/web-components/es/icons/view--off/16.js';
import View16 from '@carbon/web-components/es/icons/view/16.js';
import Debug from "debug";
import pdqHotkeyService from "../services/PdqHotkeyService";
import {center, col, grow, margin, marginAll, padding, row} from "../styles";
import {TAG_TYPE} from "@carbon/web-components/es/components/tag/defs";
import {TemplateResult} from "lit-html/development/lit-html";
type CarbonIcon = ({children, ...attrs}?: {children?: SVGTemplateResult; [attr: string]: any;}) => SVGTemplateResult;

export interface PdqDiscoverable extends LitElement {
  show: boolean
  dataPreview: string
}

type Constructor<T = {}> = new (...args: any[]) => T;

export function pdqDiscoveryLayer <T extends Constructor<PdqDiscoverable>>(
  log: Debug.Debugger,
  icon: CarbonIcon,
  name: string,
  color: string,
  tagColor: TAG_TYPE
): (constructor: T) => T {
  return function (constructor: T): T {    
    class DecoratedClass extends constructor implements PdqDiscoverable {
      protected showPdqDataSubscription: undefined|(() => void) = undefined;

      static get styles() {
        const originalStyles = super.styles as any;
        // Combine original styles with the injected styles
        return [originalStyles, row, col, grow, center, padding, marginAll,
          css`
          :host {
              border: solid 1px transparent;
          }
          :host(.config-layer-active) {
            display: block;
            border: solid 1px ${unsafeCSS(color)}; 
          }
          :host(.config-layer-active) .show {
              background: ${unsafeCSS(color)}
          }
          .toolbar {
            background: rgba(0,0,0, 0.1)   
          }
        `];
      }
      connectedCallback() {
        super.connectedCallback();
        this.showPdqDataSubscription = pdqHotkeyService.showPdqData.subscribe((show: boolean) => {
          if(show) {
            this.classList.add('config-layer-active')
          } else {
            this.classList.remove('config-layer-active')
          }
          this.requestUpdate()
        })
      }

      disconnectedCallback() {
        super.disconnectedCallback();
        if (this.showPdqDataSubscription)
          this.showPdqDataSubscription()
      }

      renderToolBar(template:TemplateResult|typeof nothing = nothing){
        return html`
        <div class="row toolbar" >
          <div class="row center ml-1 mr-1">
            ${icon()}
            <cds-tag type="${tagColor}" title="">${name}#${this.id}</cds-tag>
          </div>
          <div class="grow"></div>
          <slot name="tool-bar">${template}</slot>
          <cds-copy-button feedback="Copied!" feedback-timeout="2000" @click="${() => navigator.clipboard.writeText(this.dataPreview)}">
            ${this.dataPreview}
          </cds-copy-button>
          <cds-icon-button @click="${this.toggleVisible}" kind="${this.show ?'secondary' : 'primary'}">
            ${this.show ? ViewOff16({ slot: 'icon' }) : View16({ slot: 'icon' }) }
            <span slot="tooltip-content"> Toggle Visibility </span>
          </cds-icon-button>
        </div>
      `
      }
      toggleVisible(){
        this.show = !this.show;
        log(`${this.id} - toggle`, this.show)
      }
    }

    return DecoratedClass as Constructor<PdqDiscoverable> & T;
  }
};

//
// export const PdqDiscoveryLayer = <T extends PdqDiscoverableConstructor<LitElement>>(Base: T) => {
//   abstract class PdqDiscoveryLayerBase extends Base {
//     abstract id: string
//     abstract log: Debug.Debugger
//     abstract show: boolean
//     abstract meta:PdqDiscoverableMeta
//     abstract requestUpdate(): () => void;
//     abstract dataPreview: string;
//
//     static styles = [
//       ...(Base as any).styles as any,
//       row, col, grow, padding, margin
//     ];
//
//     protected constructor(...args: any[]) {
//       super(args);
//       pdqHotkeyService.showPdqData.subscribe((show: boolean) => {this.requestUpdate()})
//
//     }
//
//     renderToolBar(){
//       return html `
//         <div class="row">
//           <div>
//             ${this.meta.icon()}
//             <cds-tag type="${this.meta.tagColor}">${this.meta.name}#${this.id}</cds-tag>
//           </div>
//           <div class="grow"></div>
//           <cds-copy-button feedback="Copied!" feedback-timeout="2000">
//             ${JSON.stringify(this.dataPreview, null, 2)}
//           </cds-copy-button>
//           <cds-icon-button @click="${this.toggle}" kind="${this.show ?'secondary' : 'primary'}">
//             ${this.show ? ViewOff16({ slot: 'icon' }) : View16({ slot: 'icon' }) }
//             <span slot="tooltip-content"> Toggle Visibility </span>
//           </cds-icon-button>
//         </div>
//       `
//     }
//     toggle(){
//       this.show = !this.show;
//       this.log(`${this.id} - toggle`, this.show)
//     }
//   }
//
//   return PdqDiscoveryLayerBase as any as PdqDiscoverableConstructor<PdqDiscoveryLayerBase> & T;
// };