import { LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property, query, state} from 'lit/decorators';

import Debug from 'debug';
import { tableBroker } from '../services/TableBroker';
import { dataBroker, DataBroker } from '../services/DataBroker';
import { notificationService } from '../services/notificationService';
import { Subscription } from 'rxjs';


const log = Debug('pdq:flow:pdq-table');
@customElement('pdq-table')
export class PdqTable extends LitElement {
  static styles = []
  @property({type: String})
  id: string = ''

  @property({type: String})
  source: string|undefined

  @property({type: String})
  index: string|undefined

  @property({type: Object})
  schema: any

  // @property({type: Number})
  // limit: number|undefined

  constructor() {
    super();
    log('created')
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    log('firstUpdated')
    this.sync();
  }
  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    log('updated')
    this.sync();
  }

  subscription: Subscription|undefined = undefined
  cacheKey = ''
  async sync() {
    const nKey = this.id + '|' + this.source
    log(`sync: ${nKey}`)
    if (!this.id || !this.source) {
      log('missing id or source... skipping')
      return
    }
    if(this.cacheKey === nKey) {
      log('no change... skipping')
      return
    }
    this.cacheKey = nKey
    if (this.subscription) {
      log('unsubscribing from old data')
      this.subscription.unsubscribe();
    }

    if (this.schema) {
      log(`creating table with schema: ${this.id}, source: ${this.source}, index: ${this.index}`)
      await tableBroker.createTableWithSchema(this.id, this.source, this.schema, this.index || '')
    } else {
      log(`upserting table: ${this.id}, source: ${this.source}, index: ${this.index}`)
      await tableBroker.setTable(this.id, this.source, this.index || '')
    }

    // try {
    //   this.subscription = (await dataBroker.getObservableDataSource(this.source)).subscribe((data) => {
    //     log('updating table data', data)
    //     tableBroker.updateDataSource(this.id!, data, this.index, this.limit);
    //   })
    //
    // } catch (e) {
    //   log(`failed to table: ${e}`)
    //   notificationService.warning(`failed to add table ${this.id}: ${e}`)
    // }
  }

  render()    {
    return nothing
  }
}
