import {customElement, property, state} from "lit/decorators";
import {html, LitElement} from "lit";
import {center, col, grow, hide, marginRight, padding, row} from "../styles";
import { tableBroker } from "../services/TableBroker";

import VariableIcon from "@carbon/web-components/es/icons/value--variable/16";
import TrashIcon from "@carbon/web-components/es/icons/trash-can/16";
import SaveIcon from "@carbon/web-components/es/icons/save/16.js";

import Debug from "debug";
const log = Debug('pdq:liquid:pdq-liquid-variable');

// <pdq-liquid-variable id="0" number="0" name="variable" view="view"></pdq-liquid-variable>
@customElement('pdq-liquid-variable')
export class PdqLiquidVariable extends LitElement {

  @property() number: number = NaN;
  @property() name: string = '';
  @property() source: any = '';
  @property() value: any = '';
  @state() dirty: boolean = false;

  static styles = [row, col, hide, grow, padding, marginRight, center]


  render() {
    return html`
        <div class="row center ${this.dirty ? 'dirty' : ''}">
            <div>${VariableIcon()}</div>
            <cds-text-input
                    size="sm"
                    placeholder="Variable Name"
                    .value=${this.name}
                    @input=${this.nameChange}
            ></cds-text-input>
            <cds-select size="sm" placeholder="Source Name" .value="${this.source}" @change="${this.sourceChange}" class="mr-1">
                ${tableBroker.getViewNames().map(i => html`<cds-select-item value="${i}">${i}</cds-select-item>`)}
            </cds-select>
            <cds-text-input
              size="sm"
              placeholder="Static Value"
              .value=${this.value}
              @input=${this.valueChange}
            ></cds-text-input>
            <cds-button @click="${this.dispatchDelete}" size="sm" kind="danger">
                ${TrashIcon({slot: 'icon'})}
            </cds-button>
            <cds-button @click="${this.dispatchChange}" size="sm" class="mr-1">
                ${SaveIcon({slot: 'icon'})}
            </cds-button>
            <div style="min-width: 100px;">
               <span class="${this.dirty ? '' : 'hide'}">Save required</span>
            </div>
            <div class="grow" style="width: 100%"></div>
        </div>
    `
  }

  nameChange(evt: Event){
    this.name = (evt.target as any)?.value;
    this.dirty = true;
  }
  valueChange(evt: Event){
    this.value = (evt.target as any)?.value;
    this.dirty = true;
  }
  sourceChange(evt: Event){
    this.source = (evt.target as any)?.value;
    this.dirty = true;
  }

  private dispatchChange() {
    log('dispatchChange')
    const event = new CustomEvent('pdq-liquid-variable-change', {
      detail: {id: this.id, name: this.name, source: this.source, value: this.value}
    });
    this.dispatchEvent(event);
    this.dirty = false;
  }
  private dispatchDelete() {
    log('dispatchDelete')
    const event = new CustomEvent('pdq-liquid-variable-remove', {
      detail: {id: this.id}
    });
    this.dispatchEvent(event);
  }
}
