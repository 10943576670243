import {customElement, property, state} from "lit/decorators";
import {html, LitElement, nothing} from "lit";
import { tableBroker } from "../services/TableBroker";

import '@carbon/web-components/es/components/copy-button/index.js';
import '@carbon/web-components/es/components/icon-button/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import Icon from '@carbon/web-components/es/icons/debug/16.js';

import Debug from "debug";
import { DONT_TRIGGER_UPDATE, IObservable, ReplaySubject } from '../observable/ReplaySubject';
import {View} from "@finos/perspective";
import {col, grow, hide, margin, padding, row} from "../styles";
import pdqHotkeyService from "../services/PdqHotkeyService";
import {PdqDiscoverable, pdqDiscoveryLayer} from "./_pdq-mixin";
import {TAG_TYPE} from "@carbon/web-components/es/components/tag/defs";
import { Subscription } from "rxjs";
import { dataBroker } from '../services/DataBroker';
const log = Debug('pdq:flow:pdq-dump');
@customElement('pdq-dump')
@pdqDiscoveryLayer(log, Icon, 'pdq-dump', 'rgba(255, 83, 236, 0.13)', TAG_TYPE.GREEN)
export class PdqDump extends LitElement implements PdqDiscoverable{
    static styles = [hide, row, col, grow, padding, margin]
    @property() show: boolean = false;
    @state() dataPreview = 'no data';

    #view: string | undefined = undefined;
    #table: string | undefined = undefined;

    @property() error: string | null = null;

    @property()
    get view() {
        return this.#view || '';
    }

    set view(value: string) {
        this.#view = value;
        this.updateData()
    }

    @property()
    get table() {
        return this.#table || '';
    }

    set table(value: string) {
        this.#table = value;
        this.updateData()
    }

    #source: string = ''
    @property()
    get source() {
        return this.#source || '';
    }

    set source(value: string) {
        this.#source = value;
        this.updateData()
    }


    private dsId: any = null;

    #subscription = Subscription.EMPTY;
    #vSubscription = Subscription.EMPTY;

    constructor() {
        super();
    }


    render() {
        log(`pdq-data: ${this.id} ${this.show} - render`)
        if (this.show|| pdqHotkeyService.showPdqData.currentValue())
            return html`
                <div class="show col ${this.error ? 'error' : ''} m-1">
                    ${this.renderToolBar()}
                    <cds-textarea value="${this.dataPreview}">
                        <label slot="label-text">Dump:</label>
                    </cds-textarea>
                </div>
            `;
        return nothing;
    }
    protected async updateData() {
        if (this.#table) {
            this.#subscription.unsubscribe()
            const ds = tableBroker.getTable(this.#table)
            this.#subscription = ds.subscribe(async view => {

               const data = await view.to_json();
               this.dataPreview = JSON.stringify(data, null, 2);
               this.requestUpdate();
               this.#vSubscription = view.on_update(async () => {
                   const data = await view.to_json();
                   this.dataPreview = JSON.stringify(data, null, 2);
                   this.requestUpdate();
                   debugger
               })
            });
        } else if (this.source) {
            this.#subscription.unsubscribe()
            const ds = await dataBroker.getObservableDataSource(this.source)
            this.#subscription = ds.subscribe(data => {
                console.log('data', data);
                this.dataPreview = JSON.stringify(data, null, 2);
                this.requestUpdate();
            });
        }
    }
}