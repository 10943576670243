
                        import worker from "../../src/js/perspective.worker.js";
                        function make_host(a, b) {
                            function addEventListener(type, callback) {
                                a.push(callback);
                            }

                            function postMessage(msg) {
                                if (Object.keys(msg).length > 0) {
                                    for (const listener of b) {
                                        listener({data: msg});
                                    }
                                }
                            }

                            return {
                                addEventListener,
                                postMessage,
                                location: {href: ""}
                            }
                        }

                        function run_single_threaded(code, e) {
                            console.error("Running perspective in single-threaded mode due to error initializing Web Worker:", e);
                            let f = Function("const self = arguments[0];" + code);
                            const workers = [];
                            const mains = [];
                            f(make_host(workers, mains));
                            return make_host(mains, workers);
                        }

                        export const initialize = async function () {
                            try {
                                const blob = new Blob([worker], {type: 'application/javascript'});
                                const url = URL.createObjectURL(blob);
                                return new Worker(url, {type: "module"});
                            } catch (e) {
                                if (window.location.protocol.startsWith("file")) {
                                    console.warn("file:// protocol does not support Web Workers");
                                } else {
                                    console.error("Error instantiating Web Worker");
                                }
    
                                return run_single_threaded(worker, e);
                            }
                        };

                        export default initialize;
                    