import { customElement, property, query, state } from 'lit/decorators';
import { css, html, LitElement, nothing, PropertyValues } from 'lit';

import {default as add16} from '@carbon/web-components/es/icons/add/16.js';
import {default as arrowRight16} from '@carbon/web-components/es/icons/arrow--right/16.js';
import {default as arrowLeft16} from '@carbon/web-components/es/icons/arrow--left/16.js';
import {default as arrowUp16} from '@carbon/web-components/es/icons/arrow--up/16.js';
import {default as arrowDown16} from '@carbon/web-components/es/icons/arrow--down/16.js';
import {default as checkmark16} from '@carbon/web-components/es/icons/checkmark/16.js';
import {default as close16} from '@carbon/web-components/es/icons/close/16.js';
import {default as delete16} from '@carbon/web-components/es/icons/delete/16.js';
import {default as download16} from '@carbon/web-components/es/icons/download/16.js';
import {default as edit16} from '@carbon/web-components/es/icons/edit/16.js';
import {default as error16} from '@carbon/web-components/es/icons/error/16.js';
import {default as help16} from '@carbon/web-components/es/icons/help/16.js';
import {default as information16} from '@carbon/web-components/es/icons/information/16.js';
import {default as menu16} from '@carbon/web-components/es/icons/menu/16.js';
import {default as overflowMenuHorizontal16} from '@carbon/web-components/es/icons/overflow-menu--horizontal/16.js';
import {default as overflowMenuVertical16} from '@carbon/web-components/es/icons/overflow-menu--vertical/16.js';
import {default as save16} from '@carbon/web-components/es/icons/save/16.js';
import {default as search16} from '@carbon/web-components/es/icons/search/16.js';
import {default as settings16} from '@carbon/web-components/es/icons/settings/16.js';
import {default as warning16} from '@carbon/web-components/es/icons/warning/16.js';
import {default as renew16} from '@carbon/web-components/es/icons/renew/16.js';

import {default as add20 } from '@carbon/web-components/es/icons/add/20.js';
import {default as arrowRight20 } from '@carbon/web-components/es/icons/arrow--right/20.js';
import {default as arrowLeft20 } from '@carbon/web-components/es/icons/arrow--left/20.js';
import {default as arrowUp20 } from '@carbon/web-components/es/icons/arrow--up/20.js';
import {default as arrowDown20 } from '@carbon/web-components/es/icons/arrow--down/20.js';
import {default as checkmark20 } from '@carbon/web-components/es/icons/checkmark/20.js';
import {default as close20 } from '@carbon/web-components/es/icons/close/20.js';
import {default as delete20 } from '@carbon/web-components/es/icons/delete/20.js';
import {default as download20 } from '@carbon/web-components/es/icons/download/20.js';
import {default as edit20 } from '@carbon/web-components/es/icons/edit/20.js';
import {default as error20 } from '@carbon/web-components/es/icons/error/20.js';
import {default as help20 } from '@carbon/web-components/es/icons/help/20.js';
import {default as information20 } from '@carbon/web-components/es/icons/information/20.js';
import {default as menu20 } from '@carbon/web-components/es/icons/menu/20.js';
import {default as overflowMenuHorizontal20 } from '@carbon/web-components/es/icons/overflow-menu--horizontal/20.js';
import {default as overflowMenuVertical20 } from '@carbon/web-components/es/icons/overflow-menu--vertical/20.js';
import {default as save20 } from '@carbon/web-components/es/icons/save/20.js';
import {default as search20 } from '@carbon/web-components/es/icons/search/20.js';
import {default as settings20 } from '@carbon/web-components/es/icons/settings/20.js';
import {default as warning20 } from '@carbon/web-components/es/icons/warning/20.js';
import {default as renew20 } from '@carbon/web-components/es/icons/renew/20.js';

import {default as add24} from '@carbon/web-components/es/icons/add/24.js';
import {default as arrowRight24} from '@carbon/web-components/es/icons/arrow--right/24.js';
import {default as arrowLeft24} from '@carbon/web-components/es/icons/arrow--left/24.js';
import {default as arrowUp24} from '@carbon/web-components/es/icons/arrow--up/24.js';
import {default as arrowDown24} from '@carbon/web-components/es/icons/arrow--down/24.js';
import {default as checkmark24} from '@carbon/web-components/es/icons/checkmark/24.js';
import {default as close24} from '@carbon/web-components/es/icons/close/24.js';
import {default as delete24} from '@carbon/web-components/es/icons/delete/24.js';
import {default as download24} from '@carbon/web-components/es/icons/download/24.js';
import {default as edit24} from '@carbon/web-components/es/icons/edit/24.js';
import {default as error24} from '@carbon/web-components/es/icons/error/24.js';
import {default as help24} from '@carbon/web-components/es/icons/help/24.js';
import {default as information24} from '@carbon/web-components/es/icons/information/24.js';
import {default as menu24} from '@carbon/web-components/es/icons/menu/24.js';
import {default as overflowMenuHorizontal24} from '@carbon/web-components/es/icons/overflow-menu--horizontal/24.js';
import {default as overflowMenuVertical24} from '@carbon/web-components/es/icons/overflow-menu--vertical/24.js';
import {default as save24} from '@carbon/web-components/es/icons/save/24.js';
import {default as search24} from '@carbon/web-components/es/icons/search/24.js';
import {default as settings24} from '@carbon/web-components/es/icons/settings/24.js';
import {default as warning24} from '@carbon/web-components/es/icons/warning/24.js';
import {default as renew24} from '@carbon/web-components/es/icons/renew/24.js';

import {default as add32} from '@carbon/web-components/es/icons/add/32.js';
import {default as arrowRight32} from '@carbon/web-components/es/icons/arrow--right/32.js';
import {default as arrowLeft32} from '@carbon/web-components/es/icons/arrow--left/32.js';
import {default as arrowUp32} from '@carbon/web-components/es/icons/arrow--up/32.js';
import {default as arrowDown32} from '@carbon/web-components/es/icons/arrow--down/32.js';
import {default as checkmark32} from '@carbon/web-components/es/icons/checkmark/32.js';
import {default as close32} from '@carbon/web-components/es/icons/close/32.js';
import {default as delete32} from '@carbon/web-components/es/icons/delete/32.js';
import {default as download32} from '@carbon/web-components/es/icons/download/32.js';
import {default as edit32} from '@carbon/web-components/es/icons/edit/32.js';
import {default as error32} from '@carbon/web-components/es/icons/error/32.js';
import {default as help32} from '@carbon/web-components/es/icons/help/32.js';
import {default as information32} from '@carbon/web-components/es/icons/information/32.js';
import {default as menu32} from '@carbon/web-components/es/icons/menu/32.js';
import {default as overflowMenuHorizontal32} from '@carbon/web-components/es/icons/overflow-menu--horizontal/32.js';
import {default as overflowMenuVertical32} from '@carbon/web-components/es/icons/overflow-menu--vertical/32.js';
import {default as save32} from '@carbon/web-components/es/icons/save/32.js';
import {default as search32} from '@carbon/web-components/es/icons/search/32.js';
import {default as settings32} from '@carbon/web-components/es/icons/settings/32.js';
import {default as warning32} from '@carbon/web-components/es/icons/warning/32.js';
import {default as renew32} from '@carbon/web-components/es/icons/renew/32.js';


import Debug from 'debug';
const log = Debug('pdq:ui:pdq-icon');
export const icons16 = new Map([
  ['add', add16],
  ['arrowRight', arrowRight16],
  ['arrowLeft', arrowLeft16],
  ['arrowUp', arrowUp16],
  ['arrowDown', arrowDown16],
  ['checkmark', checkmark16],
  ['close', close16],
  ['delete', delete16],
  ['download', download16],
  ['edit', edit16],
  ['error', error16],
  ['help', help16],
  ['information', information16],
  ['menu', menu16],
  ['overflowMenuHorizontal', overflowMenuHorizontal16],
  ['overflowMenuVertical', overflowMenuVertical16],
  ['save', save16],
  ['search', search16],
  ['settings', settings16],
  ['warning', warning16],
  ['renew', renew16]
])
export const icons20 = new Map([
  ['add', add20],
  ['arrowRight', arrowRight20],
  ['arrowLeft', arrowLeft20],
  ['arrowUp', arrowUp20],
  ['arrowDown', arrowDown20],
  ['checkmark', checkmark20],
  ['close', close20],
  ['delete', delete20],
  ['download', download20],
  ['edit', edit20],
  ['error', error20],
  ['help', help20],
  ['information', information20],
  ['menu', menu20],
  ['overflowMenuHorizontal', overflowMenuHorizontal20],
  ['overflowMenuVertical', overflowMenuVertical20],
  ['save', save20],
  ['search', search20],
  ['settings', settings20],
  ['warning', warning20],
  ['renew', renew20]
])
export const icons24 = new Map([
  ['add', add24],
  ['arrowRight', arrowRight24],
  ['arrowLeft', arrowLeft24],
  ['arrowUp', arrowUp24],
  ['arrowDown', arrowDown24],
  ['checkmark', checkmark24],
  ['close', close24],
  ['delete', delete24],
  ['download', download24],
  ['edit', edit24],
  ['error', error24],
  ['help', help24],
  ['information', information24],
  ['menu', menu24],
  ['overflowMenuHorizontal', overflowMenuHorizontal24],
  ['overflowMenuVertical', overflowMenuVertical24],
  ['save', save24],
  ['search', search24],
  ['settings', settings24],
  ['warning', warning24],
  ['renew', renew24]
])
export const icons32 = new Map([
  ['add', add32],
  ['arrowRight', arrowRight32],
  ['arrowLeft', arrowLeft32],
  ['arrowUp', arrowUp32],
  ['arrowDown', arrowDown32],
  ['checkmark', checkmark32],
  ['close', close32],
  ['delete', delete32],
  ['download', download32],
  ['edit', edit32],
  ['error', error32],
  ['help', help32],
  ['information', information32],
  ['menu', menu32],
  ['overflowMenuHorizontal', overflowMenuHorizontal32],
  ['overflowMenuVertical', overflowMenuVertical32],
  ['save', save32],
  ['search', search32],
  ['settings', settings32],
  ['warning', warning32],
  ['renew', renew32]
])
export const icons = new Map([
  [16, icons16],
  [20, icons20],
  [24, icons24],
  [32, icons32
])


@customElement('pdq-icon')
export class PdqIcon extends LitElement {
  _iconFunc: any = null;
  _icon: string = '';
  @property({ type: String })
  get icon() {
    return this._icon
  }
  set icon(value: string) {
    this._icon = value;
    const collection = icons.get(this.size)
    if (!collection) {
      this.error('invalid size');
      return
    }
    const icon = collection.get(value)
    if (!icon) {
      this.error('invalid icon name')
      return;
    }
    this._iconFunc = icon
  }
  @property({ type: Number }) size = 16;
  @property({ type: Object }) options = {};
  static styles = css``;

  render() {
    if (!this._iconFunc)
      return nothing
    return html`${this._iconFunc(this.options)}`
  }

  error (msg: string): void {
    log('WARNING:' + msg)
    this.size = 16
    this.icon = 'warning'
  }
}
