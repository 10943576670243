import { customElement, property } from 'lit/decorators';
import { css, html, LitElement, nothing } from 'lit';
import '@carbon/web-components/es/components/modal';
import { PropertyValues } from '@lit/reactive-element';
import { googleAuth } from './googleAuth';
import Debug from 'debug';
const log = Debug('pdq:google:pdq-google-auth');

@customElement('pdq-google-auth')
export class PdqGoogleAuthElement extends LitElement {

  @property({ type: String })
  clientId = '';

  @property({ type: String })
  scopes: string = '';

  @property({type: Boolean})
  attemptAutoLogin = false

  static styles = css``;

  render() {
    return nothing;
  }

  updated(changedProperties: PropertyValues) {
    log('updated');
    this.updateLoginIfNeeded();
  }

  firstUpdated() {
    log('firstUpdated');
    this.updateLoginIfNeeded();
  }

  private lastLoginKey = ''
  private updateLoginIfNeeded() {
    log('updateLoginIfNeeded',this.clientId && this.scopes, this.clientId , this.scopes);

    if (this.clientId && this.scopes) {
      const loginKey =  this.clientId + this.scopes
      if (this.lastLoginKey === loginKey)
        return
      this.lastLoginKey = loginKey
      log('calling setupAndLogin');
      googleAuth.setupAndLogin(
        this.clientId,
        this.scopes.split(',').map(i => i.trim()),
        this.attemptAutoLogin
      );
    }
  }
}
