import { customElement, property, query, state } from 'lit/decorators.js';
import { css, html, LitElement, nothing, PropertyValues, unsafeCSS } from 'lit';
import { tableBroker } from '../services/TableBroker';

import '@finos/perspective-viewer/dist/esm/perspective-viewer.inline.js';
import '@finos/perspective-viewer-datagrid/dist/esm/perspective-viewer-datagrid.js';

import '@carbon/web-components/es/components/copy-button/index.js';
import '@carbon/web-components/es/components/icon-button/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import Icon from '@carbon/web-components/es/icons/debug/16.js';
import Upload from '@carbon/web-components/es/icons/upload/16.js';
import Download from '@carbon/web-components/es/icons/download/16.js';

import Debug from 'debug';
import { Table, View, ViewConfig } from '@finos/perspective';
import { col, grow, hide, margin, padding, row } from '../styles';
import pdqHotkeyService from '../services/PdqHotkeyService';
import { PdqDiscoverable, pdqDiscoveryLayer } from './_pdq-mixin';
import { TAG_TYPE } from '@carbon/web-components/es/components/tag/defs';
import '@finos/perspective-viewer/dist/esm/perspective-viewer.inline';

import staticStyles from 'bundle-text:@finos/perspective-viewer/dist/css/themes.css';
import { defer,  extractTextFromScripts } from '../utils';
import { notificationService } from '../services/notificationService';
import { ExtendedDatagridPlugin } from './extended-datagrid-plugin';
import { firstValueFrom, from, Subscription } from 'rxjs';
import { dataBroker } from '../services/DataBroker';
import { HTMLPerspectiveViewerElement } from '@finos/perspective-viewer';

const PRE_INIT = Symbol('PRE-INIT');
const log = Debug('pdq:flow:perspective-viewer');

export interface SlicerOption {
  label: string;
  column: string
  operator: string
  placeholder: string
  value: string|undefined
}

@customElement('pdq-perspective-viewer')
@pdqDiscoveryLayer(log, Icon, 'pdq-perspective-viewer', 'rgba(83, 83, 236, 0.13)', TAG_TYPE.PURPLE)
export class PdqPerspectiveViewer extends LitElement implements PdqDiscoverable {
  static styles = [hide, row, col, grow, padding, margin,
    unsafeCSS(staticStyles),
    css`perspective-viewer {font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif!important;}`,
    css`.dpl-slicer{width: 100%}`,
    css`
        td.psp-align-right,  td.psp-align-left, th.psp-align-right,  th.psp-align-left  {
            text-overflow: ellipsis;
            overflow-x: hidden;
        }
    `
  ];

  private __table: Table | undefined
  private _perspectivePresent = defer();
  private _perspectiveLoaded = defer()
  private _loaded = false
  static next_id = 0;
  _id = PdqPerspectiveViewer.next_id++;

  @property({ type: String }) id: string = `pdq-perspective-viewer-${this._id}`;
  @property({ type: Boolean}) show: boolean = false;
  @property({ type: String}) theme: string = 'auto' // 'Pro Light';
  @property({ type: String}) height: string = '500px';
  @property({ type: Boolean}) hideIndex: boolean = false;
  @property({ type: Array}) hiddenColumns: string[] = [];
  @property({ type: Boolean }) selectable: boolean = false;

  private _slicers: SlicerOption[] = []
  @property({ type: Array })
  get slicers () : SlicerOption[] {
    return this._slicers
  }
  set slicers (value: SlicerOption[] | string[]) {
    const oldValue = this._slicers
    const s = []
    for (const v of value) {
      if (!v)
        continue
      if (typeof v == 'string') {
        s.push({
          label: v,
          column: v,
          operator: '==',
          placeholder: 'All',
          value: undefined
        } as SlicerOption)
      } else if (v.column) {
        s.push({
          label: v.label || v.column,
          column: v.column,
          operator: v.operator || '==',
          placeholder: v.placeholder || 'All',
          value: v.value || undefined
        } as SlicerOption)
      }
    }
    this._slicers = s
    this.requestUpdate('slicers', oldValue)
  }

  @state() dataPreview = 'no data';

  #table: string | undefined = undefined;

  @property() error: string | null = null;

  @property({ type: String })
  get table() {
    return this.#table || '';
  }
  set table(value: string) {
    this.#table = value;
    this.updateData();
  }

  @property({ type: Object })
  config: any = {};

  #perspectiveViewer:HTMLPerspectiveViewerElement | undefined
  @query('perspective-viewer')
  get perspectiveViewer(){
    return this.#perspectiveViewer;
  }
  set perspectiveViewer(value: HTMLPerspectiveViewerElement | undefined){
    if (value)
      this.#perspectiveViewer = value;
  }
  @query('cds-textarea')
  configEditor: HTMLTextAreaElement | undefined;

  _listenersAdded = false
  addEventListeners(){
    if (!this.perspectiveViewer)
      return
    if (this._listenersAdded)
      return;

    this._listenersAdded = true
    log('adding listeners');

    const tableName = this.#table
    if (!tableName)
      return

    dataBroker.upsertDerivation(`${this.id}:row`, [`${this.id}:selected`], async ([row]: any) => {
      log(`###: ${this.id}:row - ${row}`)

      const table = await firstValueFrom(tableBroker.getTable(tableName));
      const index = tableBroker.getTableMeta(tableName).index as string;
      const view = await table.view({filter: [
          [index, "==", row[0][index]]
        ]});

      const json =  await view.to_json();
      log(`DATA ISSUES`, json);
      return json
    });
    this.perspectiveViewer?.addEventListener('keydown', (evt) => {
      try {
        if (evt.key === 'Escape') {
          // document.body.classList.remove('details-open')
          // document.querySelector('.detail').innerHTML = ''
          this.dispatchEvent(new CustomEvent('escape'));
        }
        if (!this.selectable)
          return;
        if (evt.key === 'ArrowDown') {
          evt.preventDefault();
          evt.stopPropagation()
          try {
            const cell = this.shadowRoot!.querySelector('perspective-viewer-datagrid')!.shadowRoot!.querySelector('regular-table')!.querySelector('.psp-row-selected');
            if (!cell?.parentElement || !cell?.parentElement?.nextElementSibling) {
              debugger
              log('ArrowDown - no selected row')
              return;
            }
            const nextSiblingElement = cell.parentElement.nextElementSibling!;
            if (nextSiblingElement) {
              const event = new MouseEvent('mousedown', { 'bubbles': true, 'cancelable': true });
              nextSiblingElement.querySelector('td')?.dispatchEvent(event);
              nextSiblingElement.scrollIntoView({ behavior: "instant", block: "center", inline: "start" });
            }
          } catch(e) {
            log(`ArrowDown - exception ${e}`)
          }
        }
        if (evt.key === 'ArrowUp') {
          evt.preventDefault();
          evt.stopPropagation()
          try {
            const cell = this.shadowRoot!.querySelector('perspective-viewer-datagrid')!.shadowRoot!.querySelector('regular-table')!.querySelector('.psp-row-selected');
            if (!cell?.parentElement || !cell?.parentElement?.previousElementSibling) {
              log('ArrowUp - no selected row')
              return;
            }
            const nextSiblingElement = cell.parentElement.previousElementSibling
            if (nextSiblingElement) {
              const event = new MouseEvent('mousedown', {'bubbles': true, 'cancelable': true});
              nextSiblingElement.querySelector('td')?.dispatchEvent(event);
              nextSiblingElement.scrollIntoView({behavior: "instant", block: "center", inline: "start"});
            }
          } catch(e) {
            log(`ArrowUp - exception ${e}`)
          }
        }
      } catch(e) {
        log(`keydown error: ${e?.message || e}`)
      }
    })
    this.perspectiveViewer?.addEventListener('perspective-config-update', (evt: any) => {
      log('perspective-config-change', evt);
      this.dispatchEvent(new CustomEvent('perspective-config-update', {detail: evt.detail}));
      this.config = evt.detail;
      this._updatePreview();
      this.hideColumnsAsRequired()
    });
    this.perspectiveViewer?.addEventListener('perspective-select', (event:any) => {
      log('perspective-select', event);
      this.dispatchEvent(new CustomEvent('perspective-select', {detail: event.detail}));
      if (!this.id)
        return
      dataBroker.setDataSource(`${this.id}:selected`, [{...event.detail.row }]);
    });
    this.perspectiveViewer?.addEventListener('perspective-click', async (evt: any) => {
      log('perspective-click', evt);
      this.dispatchEvent(new CustomEvent('perspective-click', {detail: evt.detail}));
    });
    this._perspectivePresent.resolve(true);
    tableBroker.addViewer(this.id, this);
    this.hideColumnsAsRequired()
  }
  syncConfigToPerspective = async () => {
    log('syncConfigToPerspective');
    try {
      this.config = JSON.parse(this.configEditor?.value || '');
      if (this.config) {
        this.perspectiveViewer?.restore(this.config);
        await this.hideColumnsAsRequired();
        await this._updatePreview();
      }
    } catch (e) {
      log(`Failed to parse config: ${e}`);
      notificationService.warning(`pdq-view#${this.id}|Error parsing JSON: \n${e?.message || e}`);
    }
  };
  syncConfigFromPerspective = async () => {
    log('syncConfigFromPerspective');
    await this._updatePreview();
  };
  render() {
    log(`pdq-data: ${this.id} ${this.show} - render`);
    const show = this.show || pdqHotkeyService.showPdqData.currentValue();
    const slot = html`<slot name="config" @slotchange="${this._onSlotChange}"></slot>`;
    const toolbar = html`
      <cds-icon-button slot="tool-bar" @click="${this.syncConfigFromPerspective}" kind="primary">
        <span slot="tooltip-content"> Sync config from perspective </span>
        ${Upload({ slot: 'icon' })}
      </cds-icon-button>
      <cds-icon-button slot="tool-bar" @click="${this.syncConfigToPerspective}" kind="primary">
        <span slot="tooltip-content"> Sync config to perspective </span>
        ${Download({ slot: 'icon' })}
      </cds-icon-button>
    `;

    return html`
      ${slot}
      ${show ? html`
        <div class="show col ${this.error ? 'error' : ''} m-1">
          ${this.renderToolBar(toolbar)}
          <cds-textarea value="${JSON.stringify(this.config, null, 2)}">
            <label slot="label-text">Config</label>
          </cds-textarea>
        </div>` : nothing}

      <div class="row slicers">
        <slot name="toolbar-left"></slot>
        <slot name="toolbar-slicers">
          <cds-form-item class="row">
            ${this.slicers.map(i => html`<cds-select class="dpl-slicer" @cds-select-selected=${this.slicerChanged} data-slicer=${i.column} data-operator=${i.operator} inline label-text="${i.label}" placeholder="${i.placeholder}" size="md" value="${i.value}"></cds-select>`)}
          </cds-form-item>
        </slot>
        <slot name="toolbar-grow">
          <div class="grow"></div>
        </slot>
        <slot name="toolbar-right"></slot>
      </div>
      <perspective-viewer
        theme=${this.theme == "auto" ? "Pro Light" : this.theme}
        style="height: ${this.height}; --column-drop-label--display: block"
        ?selectable="${this.selectable}"
        id="pdq-perspective-viewer-${this.id || this._id}">
      </perspective-viewer>
    `;
  }

  #subscription = Subscription.EMPTY;
  protected async updateData() {
    log('updateData');
    if (this.#table) {
      this.#subscription.unsubscribe()
      log(`awaiting table: ${this.#table}`);
      const table = await tableBroker.getTable(this.#table);
      log(`acquired table: ${this.#table}`);
      await this._perspectivePresent.promise;

      log('loading table');
      this.#subscription = table!.subscribe(async t => {
        this.#subscription.unsubscribe()
        this.#subscription = table!.subscribe(async t => {
          await this.hideColumnsAsRequired();
          await this._updateSlicers(t)
          await this._updatePreview();
        })

        log('table changed');
        this.__table = t
        const config = this.config || {};
        await this.perspectiveViewer?.load(t);
        log('restoring config', config);
        await this.perspectiveViewer?.restore(config);
        this.config = config;
        await this.hideColumnsAsRequired();
        await this._perspectiveLoaded.resolve(true);
        await this._updateSlicers(t)
        await this._updatePreview();
        this.perspectiveViewer?.setAttribute('theme', this.theme)
        this._loaded = true
      });
    } else {
      log('updateData: no table to load...');
    }
  }
  private async _onSlotChange(event: Event) {
    log(`${this.id} - slot change`);
    const slot = event.target as HTMLSlotElement;
    const nodes = slot.assignedNodes({ flatten: true });
    const text = extractTextFromScripts(nodes);
    if (!text.trim()) {
      log(`ignoring blank config`);
      return;
    }
    try {
      //debugger
      await this._perspectiveLoaded.promise
      this.config = JSON.parse(text);
      await this.perspectiveViewer!.restore(this.config);
      await this.hideColumnsAsRequired();
      await this._updatePreview();

    } catch (e: any) {
      log(`WARNING - pdq-view#${this.id}|Error parsing JSON: \n${e?.message || e}`);
      notificationService.warning(`pdq-view#${this.id}|Error parsing JSON: \n${e?.message || e}`);
    }
  }
  private async _updatePreview() {
    await this._perspectiveLoaded.promise
    log('update preview');
    //this.config = await this.perspectiveViewer?.save();
    this.dataPreview = `<pdq-perspective-viewer${this.id ? html` id="${this.id}"` : ''}${this.table ? html` table="${this.table}"` : ''}><template>${
      JSON.stringify(this.config, null, 2)}</template></pdq-perspective-viewer>`;
  }
  private styleSheet:CSSStyleSheet|undefined
  hideColumnsAsRequired = async () => {
    if (!this.perspectiveViewer)
      return

    if (!this.styleSheet) {
      const rg = this.perspectiveViewer?.querySelector('perspective-viewer-datagrid')?.shadowRoot
      if (!rg)
        return;

      this.styleSheet = new CSSStyleSheet();
      rg.adoptedStyleSheets = [...rg.adoptedStyleSheets, this.styleSheet];
    }

    const table = tableBroker.getTableMeta(this.table)
    const colsToHide = []

    const index = await table?.index
    if (index && this.hideIndex) {
      colsToHide.push(index)
    }
    if (this.hiddenColumns && this.hiddenColumns.length > 0)
      colsToHide.push(...this.hiddenColumns)
    if (!this.config)
      return
    const style = colsToHide.map(i => {
        let idx = this.config?.columns?.indexOf(i);
        if (idx === -1)
          return ''
        idx++
        console.log(`hiding column ${String(i)} at index ${idx}`);
        return `
          th:nth-child(${idx}) {
            display: none;
          }
          td:nth-child(${idx}) {
            display: none;
          }
        `;
      }).join('')

    console.log('style', style);
    this.styleSheet.replaceSync(style.toString());
  }
  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    log('CHANGE', _changedProperties)
    this.addEventListeners()
    if (_changedProperties.has('config')) {
      if (this.config && this.perspectiveViewer && this._loaded) {
        this.perspectiveViewer.restore(this.config)
      }
    }
  }
  protected firstUpdated(props: PropertyValues) {
    this.addEventListeners()
    this.updateTheme()
  }
  connectedCallback() {
    super.connectedCallback();
    this.updateTheme()
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.#subscription.unsubscribe();
    tableBroker.removeViewer(this.id);
  }
  private async _updateSlicers(table: Table) {
    if (this.slicers.length === 0)
      return
    log('update slicers')
    const slicerElements = <any[]> (this.shadowRoot?.querySelectorAll('.dpl-slicer') || [])
    const innerFunc = async (slicer: HTMLSelectElement) => {
      log('update slicers ... inner', slicer)
      try {
        const col = slicer.dataset.slicer;
        const config = {
          "group_by": [col],
          "columns": [col],
          "aggregates": {} as any
        } as ViewConfig;
        config.aggregates![`${col}`] = "distinct count"
        const v = await table.view(config);
        const data = (await v.to_json()).map((i: any) => i.__ROW_PATH__[0])
        await v.delete()

        const viewConfig = await this.perspectiveViewer!.save()
        if (!viewConfig.filter)
          viewConfig.filter = []
        const existingFilter = viewConfig.filter.filter(i => i[0] === col);
        let selectedValue: any = undefined;
        if (existingFilter.length > 0) {
          selectedValue = existingFilter[0][2]
        }
        //console.log('data', data);
        [...(slicer.options as any)].map(i => slicer.options.remove(i));
        data.forEach((i) => {
          //console.log(`"i" ${typeof i}`)
          const item = document.createElement('option');
          item.classList.add('cds--select-option')
          item.setAttribute('value', i || null);
          item.textContent = i === undefined ? 'All' : i;
          if (i === selectedValue) {
            item.setAttribute('selected', 'selected')
          }
          slicer.options.add(item);
        });
      }catch(e){
        log(`update slicer failed: ${e}`, slicer)
      }
    }

    if (!table || !this.perspectiveViewer || !slicerElements || slicerElements.length == 0)
      return

    const promises = []
    for (const slicer of [...slicerElements]) {
      promises.push(innerFunc(slicer))
    }
    await Promise.all(promises)
  }
  slicerChanged(evt: Event){
    const target = evt.target as HTMLSelectElement;
    const col = target.dataset['slicer'];
    const operator = target.dataset['operator'];
    const value = target.value;

    const filter = this.config.filter = [
      ...(this.config.filter || []).filter((i:any) => i[0] != col),
    ];
    if (value !== null && value !== 'null')
      filter.push([col, operator, value])

    const old = this.config
    this.config = {...this.config, filter}
    this.requestUpdate('config', old)
  }
  private updateTheme(){
    if (this.theme != 'auto')
       return

    const themes = new Set(['cds-theme-zone-white','cds-theme-zone-g10','cds-theme-zone-g90','cds-theme-zone-g100'])
    let theme = null
    let el = this as any;

    while (theme == null && el) {
      const classList = el.classList || []
      for (const c of classList) {
        if (themes.has(c)) {
          theme = c
          break;
        }
      }
      if (el.parentElement) {
        el = el.parentElement;
      } else if (el.parentNode instanceof ShadowRoot) {
        el = el.parentNode.host as HTMLElement;
      } else {
        el = null;
      }
    }
    this.perspectiveViewer?.resetThemes(['Pro Light', 'Pro Dark'])
    log(`closest outside theme = ${theme}`)
    switch (theme){
      case 'cds-theme-zone-white':
      case 'cds-theme-zone-g10':
        this.theme = 'Pro Light'
        break
      case 'cds-theme-zone-g90':
      case 'cds-theme-zone-g100':
        this.theme = 'Pro Dark'
        break
      default:
      // do nothing
    }
    this.perspectiveViewer?.setAttribute('theme', this.theme)
    setTimeout(() => {this.perspectiveViewer?.setAttribute('theme', this.theme)}, 300) // why needed?
  }
}

