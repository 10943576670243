import { customElement, property, query, state } from 'lit/decorators';
import { css, html, LitElement, nothing, PropertyValues, svg } from 'lit';
import { googleAuth } from './googleAuth';
import { BUTTON_KIND, BUTTON_TYPE, BUTTON_SIZE } from '@carbon/web-components/es/components/button/button';
import '@carbon/web-components/es/components/button/button-skeleton.js';
import User20 from '@carbon/web-components/es/icons/user/20';
import Debug from 'debug';
import { col, row } from '../styles';

const log = Debug('pdq:google:pdq-google-login-button');

@customElement('pdq-google-identity')
export class GoogleIdentityBadge extends LitElement{
  static styles = [
    row, col,
    css` 
    .icon {
      margin-right: 8px
    }
    .profile-img {
        max-height: 36px;
        max-width: 36px;
    }
  `];


  render() {
    const user = googleAuth.token.value?.user;
    if (!user)
      return nothing;

    return html `<div class="row">
        <img src=${user.picture} class="icon profile-img">
        <div class="col">
          <div>Logged in as ${user.given_name} ${user.family_name}</div>
          <div>${user.email}</div>
        </div>
      </div>`
  }
}

@customElement('pdq-google-login-button')
export class PdqGooglePassiveLoginButton extends LitElement {
  @property() size: BUTTON_SIZE = BUTTON_SIZE.MEDIUM;
  @property() kind: BUTTON_KIND = BUTTON_KIND.PRIMARY;
  @property() showIdentity = true;

  @query('#google-login-button') element!: HTMLElement;
  @state() loading = true;
  @state() loggedIn = false;

  static styles = [
    row, col,
    css` 
    .icon {
      margin-right: 8px
    }
    .profile-img {
        max-height: 36px;
        max-width: 36px;
    }
    `];

  constructor() {
    super();
    googleAuth.loggedIn.subscribe(i => {
      log(`loggedIn = ${i}`);
      this.loggedIn = i;
      this.loading = false
    });
  }

  render() {
    log('render', this.showIdentity);
    if (this.loading)
      return html`
        <cds-button disabled="true" size=${this.size} >
          ${User20({ class: 'icon' })}
          Google Loading...
        </cds-button>
      `;

    if (!this.loggedIn)
      return html`
        <cds-button @click=${this.login} size=${this.size} kind=${this.kind}>
          ${User20({ class: 'icon' })}
          Sign In with Google
        </cds-button>`;

    return html`
      ${this.showIdentity ? html`<pdq-google-identity></pdq-google-identity>` : nothing}
      <cds-button @click=${this.logout} size=${this.size} kind=${this.kind}>
        ${User20({ class: 'icon' })}
        Sign Out of Google
      </cds-button>`;

  }

  login() {
    this.loading = true;
    googleAuth.login();
    setTimeout(() => this.loading = false, 5000);
  }

  logout() {
    this.loading = true;
    googleAuth.logout();
  }
}