import {ReplaySubject} from "../observable/ReplaySubject";
import {PdqConfirmDialog} from "../ui/pdq-confirm-dialog";

export interface Notification {
    type: 'error' | 'info' | 'success' | 'warning' | 'trace'
    message: string;
}

export class NotificationService {
    notifications = new ReplaySubject<Notification>(1)

    constructor() {
        // this.notifications.subscribe(i => console.log(i))
    }

    error(message: string){
        this.notifications.next({type: 'error', message})
    }
    info(message: string){
        this.notifications.next({type: 'info', message})
    }
    success(message: string){
        this.notifications.next({type: 'success', message})
    }
    warning(message: string){
        this.notifications.next({type: 'warning', message})
    }

    trace(message: string) {
        this.notifications.next({type: 'trace', message})
    }

    async confirm(question: string, actions: string[] = ['Confirm', 'Cancel'], title = 'Confirm Action'): Promise<string> {
        return new Promise((resolve, reject) => {
            const el = document.createElement('pdq-confirm-dialog') as PdqConfirmDialog;
            el.actions = actions;
            el.header = title;
            el.message = question;
            el.addEventListener('action-selected', (evt: Event) =>{
                const event = evt as CustomEvent;
                resolve(event.detail as string)
            });
            document.body.appendChild(el);
        });
    }
}

export const notificationService = new NotificationService();
