import * as CryptoJS from 'crypto-js';
import slugify from "slugify";
//import Pica from "pica";
import Debug from 'debug';

export function extractTextFromScripts(nodes: Node[]) {
  let template = ''
  for (const n of nodes) {
    switch (n.nodeType) {
      case Node.ELEMENT_NODE:
        const el = n as Element;
        if (el.tagName === 'SCRIPT') {
          template += el.textContent;
        }
    }
  }
  return template;
}

export function extractTextAndScripts(nodes: Node[], template='', scripts: string[] = []) {
    for(const n of nodes) {
        switch (n.nodeType) {
            case Node.TEXT_NODE:
                template += (n as Text).data;
                break;
            case Node.ELEMENT_NODE:
                const el = n as Element;
                // console.log(el.tagName);
                switch (el.tagName) {
                    case 'SCRIPT':
                        if(el.attributes.getNamedItem('type')?.value == 'text/liquid') {
                          template += el.textContent;
                        } else if(el.attributes.getNamedItem('type')?.value == 'application/json') {
                          template += el.textContent;
                        } else {
                          scripts.push(el.innerHTML);
                        }
                        continue;
                    case 'TEMPLATE':
                        //console.log('TEMPLATE:', el.outerHTML);
                        const outerHtml =  el.outerHTML;
                        const startIndex = outerHtml.indexOf('>');
                        const endIndex = outerHtml.lastIndexOf('<');
                        template += outerHtml.substring(startIndex+1, endIndex);
                        continue;
                        // const t = el as HTMLTemplateElement;
                        // const childNodesArray = Array.from(t.content.childNodes) as Node[]; // Convert to Node[]
                        // const ret = extractTextAndScripts(childNodesArray, template, scripts);
                        // template += ret.template;
                        // scripts = ret.scripts;
                        // continue;
                   case 'COMMENT':
                      console.log('TEMPLATE COMMENT:', el.outerHTML);

                    default:
                        template += el.outerHTML;
                }
                break;
            default:
                const a = n as any;
                console.log(`n[${n.nodeType}]: innerHTML=${a.innerHTML} data=${a.data} textContent=${n.textContent} outerHTML=${a.outerHTML}`);
        }
    }
    return {template, scripts}
}
export async function getSha256(message: string|Blob) {
  let hashBuffer;
  if (message instanceof Blob) {
    const arrayBuffer = await message.arrayBuffer();
    hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
  }else {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    hashBuffer = await crypto.subtle.digest('SHA-256', data);
  }
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
}
export function calculateFileMD5(file: File): Promise<string> {
    return  new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const arrayBuffer = event.target!.result as ArrayBuffer;
            const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
            const hash = CryptoJS.MD5(wordArray).toString();
            resolve(hash);
            console.log('MD5 Hash:', hash);
        };

        reader.onerror = function (error) {
            console.error('Error reading file:', error);
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
}
export function safeSlugifyFilename(filename: string): string {
  const parts = filename.split('.');
  const extension = parts.pop(); // Remove and save the last part as the extension
  const baseName = parts.join('.'); // Handle cases where there are multiple dots

  // Slugify options
  const options = {
    replacement: '-',    // replace spaces with replacement character, defaults to `-`
    remove: undefined,   // remove characters that match regex, defaults to `undefined`
    lower: true,         // convert to lower case, defaults to `false`
    strict: true,        // strip special characters except replacement, defaults to `false`
    locale: 'en',        // language code of the locale to use
    trim: true           // trim leading and trailing replacement characters
  };

  // Only slugify the base name, and then append the original extension
  return `${slugify(baseName, options)}.${extension}`;
};
export function iterateFilename(filename: string) {
  // Regular expression to match the filename, an optional dash followed by a number, and the file extension
  const regex = /^(.*?)(-?)(\d+)?(\.[^.]+)$/;

  // Use the regular expression to extract parts of the filename
  const match = filename.match(regex);

  if (match) {
    const [, base, dash, number, extension] = match;
    let nextNumber: number;

    if (number) {
      // If a number exists, increment it
      nextNumber = parseInt(number, 10) + 1;
    } else {
      // If no number exists, start with 2
      nextNumber = 2;
    }

    // Return the new filename with the incremented number
    return `${base}-${nextNumber}${extension}`;
  } else {
    // If the filename doesn't match the expected pattern, return it unchanged
    return filename;
  }
}
export function stripSlashesFromRight(str: string): string {
    return str.replace(/\/+$/, '');
}

// const pica = new Pica();
// export function createThumbnail(file: any) {
//     return new Promise(async (resolve, reject)=>{
//         const reader = new FileReader();
//         reader.onload = function(event:any) {
//             const img = new Image();
//             img.onload = async function() {
//                 // Define maximum dimensions for the thumbnail
//                 const maxDimension = 100; // This is an example, adjust as needed
//
//                 // Calculate the scaling factor to maintain aspect ratio
//                 let scaleFactor = Math.min(maxDimension / img.width, maxDimension / img.height);
//                 let newWidth = img.width * scaleFactor;
//                 let newHeight = img.height * scaleFactor;
//
//                 const canvas = document.createElement('canvas');
//                 canvas.width = newWidth;
//                 canvas.height = newHeight;
//
//                 // Resize the image with Pica
//                 let q = 1;
//                 let blob: Blob;
//                 do {
//                     q -= 0.2
//                     blob = await picaResize(img, canvas, q);
//                 }while(q > 0 && blob.size > 1024);
//
//                 // Now you have a blob with your image
//                 console.log('Thumbnail created', blob.size);
//
//                 // Additional steps to use the blob (e.g., display it or upload it) go here
//                 // For example, to display the thumbnail:
//                 const url = URL.createObjectURL(blob);
//                 const imageElement = document.createElement('img');
//                 imageElement.src = url;
//                 document.body.appendChild(imageElement);
//
//             };
//             img.src = event.target.result;
//         };
//         reader.readAsDataURL(file);
//     })
//
//     async function picaResize(img: HTMLImageElement, canvas: HTMLCanvasElement, quality: number): Promise<Blob>{
//         console.log('Resizing image with quality', quality);
//         const resizedCanvas = await pica.resize(img, canvas);
//         return pica.toBlob(resizedCanvas, 'image/webp', quality);
//     }
//
// }
export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(event) {
            try {
                const arrayBuffer = event.target?.result;
                if (!(arrayBuffer instanceof ArrayBuffer))
                    throw new Error('Failed to read blob. ArrayBuffer is null');

                resolve(arrayBuffer);
                // const uint8Array = new Uint8Array(arrayBuffer);
                // resolve(uint8Array);
            }catch (e){
                reject(e);
            }
        };
        reader.readAsArrayBuffer(blob);
    })

}


export function defer<T>(): {promise: Promise<T>, resolve: (value: T) => void, reject: (reason?: any) => void} {
  let res: any = undefined
  let rej: any = undefined
  const promise = new Promise<T>((resolve, reject)=> {
    res = resolve;
    rej = reject;
  });
  const resolve = <((value: T) => void)> res ;
  const reject = <((reason?: any) => void)> res ;

  return {
    promise,
    resolve,
    reject
  };
}

export function delay(delayMs: number = 1000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delayMs));
}
export function safeFireAndForgetFactory(log: Debug.Debugger){
  return function safeFireAndForget<T>(promise: Promise<T>, message: string = 'operation completed') {
    promise.then(()=> log(message)).catch((e)=> log(message, e));
  }
}

const mutextLog = Debug('pdq:mutex');
export class Mutex {
  private _queue: any[];
  private _locked: boolean;

  isLocked = () => this._locked;
  hasQueue = () => this._queue.length > 0;

  constructor(private name: string) {
    this._queue = [];
    this._locked = false;
  }

  lock(): Promise<() => void> {

    const unlock = () => {
      mutextLog(`[${this.name}] unlocking... ${this._queue.length}`)
      this._locked = false;
      if (this._queue.length > 0) {
        this._queue.shift()();
      }
    };

    if (this._locked) {
      mutextLog(`[${this.name}] locked... adding to queue... ${this._queue.length}`)
      return new Promise(resolve => {
        this._queue.push(() => resolve(unlock));
      });
    } else {
      mutextLog(`[${this.name}] locking... ${this._queue.length}`)
      this._locked = true;
      return Promise.resolve(unlock);
    }
  }
}
export const blobToJson = (blob: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(blob);
  });
};