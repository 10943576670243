import { customElement, property, query, state } from 'lit/decorators';
import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import OverflowMenuVertical16 from '@carbon/web-components/es/icons/overflow-menu--vertical/16.js';

import Debug from 'debug';
const log = Debug('pdq:ui:pdq-theme');

@customElement('pdq-theme')
export class PdqTheme extends LitElement {

  @property({ type: String }) target = 'body';
  @property({ type: String, reflect: true }) theme = 'cds-theme-zone-white';
  @property({ type: Object, reflect: true }) options = {
    'White': 'cds-theme-zone-white',
    'Light': 'cds-theme-zone-g10',
    'Dark': 'cds-theme-zone-g90',
    'Black': 'cds-theme-zone-g100',
  }
  static styles = css`
    :host {
        position: relative;
    }
    cds-overflow-menu-body {
      background: var(--cds-layer-01, white);
    }
    cds-overflow-menu-item{
      background: var(--cds-layer-01, white);
    }
  `;
  private observer: MutationObserver|undefined;

  render() {
    return html`
        <cds-overflow-menu>
          ${OverflowMenuVertical16({ class: `cds--overflow-menu__icon`, slot:'icon'})}
          <span slot="tooltip-content"> Theme </span>
          <cds-overflow-menu-body style="background: var(--cds-layer-01, white);">
            ${Object.keys(this.options).map(key => {
              return html`<cds-button kind="secondary" size="sm" style="width: 100%"
                                      @click=${() => this.themeChange((this.options as any)[key])}>${key}</cds-button>`  
            })}
          </cds-overflow-menu-body>
        </cds-overflow-menu>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.themeChange(this.theme)
  }

  themeChange (evt: any) {
    log(`themeChange called with ${evt}`)
    const el = document.querySelector(this.target)
    if (!el) {
      log(`target '${this.target}' not found`)
      return
    }

    const values = Object.values(this.options)
    if (!values.includes(evt)) {
      log(`target '${this.target}' not found`)
      return
    }
    this.theme = evt
    values.map(i => el.classList.remove(i))
    el.classList.add(this.theme)
    log('themeChange changed to', evt)
  }

  // connectedCallback() {
  //   super.connectedCallback();
  //   this.observer = new MutationObserver((mutationsList) => {
  //     for (const mutation of mutationsList) {
  //       if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
  //         console.log('Class attribute changed:', mutation);
  //         const values = Object.values(this.options)
  //         if (!values.includes()) {
  //
  //         }
  //       }
  //     }
  //   });
  //
  //   this.observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
  // }
  //
  // disconnectedCallback() {
  //   if (this.observer) {
  //     this.observer.disconnect();
  //   }
  //   super.disconnectedCallback();
  // }

}
