import {customElement, property} from "lit/decorators";
import { html, LitElement, nothing } from 'lit';
import { center, col, row } from '../styles';
import {LiquidVariable} from "./liquid-variable";
import Debug from "debug";

const log = Debug('pdq:liquid:pdq-liquid-variable-stack');

@customElement('pdq-liquid-variable-stack')
export class PdqLiquidVariableStack extends LitElement {
  @property({type: Array}) variables: LiquidVariable[] = [];

  static styles = [col, row, center]

  render() {
    return html`
        <cds-form-group legend-text="Variables & Data Mapping" style="margin-bottom: 15px">
            <div class="col">
              ${this.variables.length > 0  ? html `
              <div class="row center">
                <div>Variable Name</div>
                <div>Data Source (table or view)</div>
                <div>Static Value</div>
              </div>` : nothing }
                ${this.variables.map(v => html`
                        <pdq-liquid-variable 
                          id="${v.id}" 
                          name="${v.name}" 
                          view="${v.source}"
                          value="${v.value}"
                          @pdq-liquid-variable-change="${(e: CustomEvent) => this.updateVariable(e.detail.id, e.detail.name, e.detail.source, e.detail.value)}"
                          @pdq-liquid-variable-remove="${(e: CustomEvent) => this.removeVariable(e.detail.id)}"
                        ></pdq-liquid-variable>
                    `)}
                <div class="row">
                    <cds-button size="sm" @click="${this.addVariable}">Add Variable</cds-button>
                </div>
            </div>
        </cds-form-group>
    `
  }

  private static nextId = 0

  private addVariable() {
    log('addVariable')
    this.variables.push({
      id: PdqLiquidVariableStack.nextId++,
      name: '',
      source: '',
      value: undefined
    });
    this.requestUpdate();
  }

  private removeVariable(id: number) {
    log(`removeVariable: ${id}`)
    this.variables = this.variables.filter(v => v.id != id);
    this.dispatchChange()
    this.requestUpdate();
  }

  private updateVariable(id: number, name: string, source: string, value: string|undefined = undefined) {
    log(`updateVariable: ${id}, ${name}, ${source},${value}`);
    const variable = this.variables.find(v => v.id == id);
    if (variable) {
      variable.name = name;
      variable.source = source;
      variable.value = value;
    }
    this.saveVariables()
  }

  private dispatchChange() {
    log('dispatchChange: pdq-liquid-variables-changed')
    const event = new CustomEvent('pdq-liquid-variables-changed', {
      detail: this.variables
    });
    this.dispatchEvent(event);
  }

  private saveVariables() {
    log(`saveVariables`)
    const event = new CustomEvent('pdq-liquid-variables-saved', {
      detail: this.variables
    });
    this.dispatchEvent(event);
  }
}