import {ReplaySubject, distinct} from "../observable/ReplaySubject";


export class PdqHotkeyService {
    #showPdqData = new ReplaySubject<boolean>(1)
    #showPdqModel = new ReplaySubject<boolean>(1)
    toggled = false;
    showPdqData = distinct(this.#showPdqData)
    showPdqModel = distinct(this.#showPdqModel);

    constructor() {
        document.addEventListener('keydown', (e) => {
            if (e.altKey) {
                this.#showPdqData.next(true)
            }
            if (e.altKey && e.ctrlKey) {
                this.toggleView()
            }
            if (e.altKey && e.shiftKey) {
                this.toggleModel()
            }
        })
        document.addEventListener('keyup', (e) => {
            if (!this.toggled)
                this.#showPdqData.next(false)
        })
    }
    toggleView(){
        this.toggled = !this.toggled;
        this.#showPdqData.next(this.toggled)
    }

    toggleModel() {
        this.#showPdqModel.next(!this.#showPdqModel.currentValue())
    }
}

export const pdqHotkeyService = new PdqHotkeyService();
export default pdqHotkeyService;