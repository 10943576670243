import { customElement, property, query, state } from 'lit/decorators';
import { css, html, LitElement } from 'lit';
import '@carbon/web-components/es/components/modal';
import { defer, safeFireAndForgetFactory } from '../utils';
import Debug from 'debug';

const log = Debug('pdq:ui:pdq-pdf-viewer');
const safeFireAndForget = safeFireAndForgetFactory(log);

@customElement('pdq-pdf-viewer')
export class PdqPdfViewer extends LitElement {
  @property({ type: Number }) pageNumber = 1;
  @property({ type: String }) zoomLevel = '';
  @property({ type: String }) viewMode = 'Fit';
  @property({ type: String }) search = '';
  @property({ type: String }) pageMode = 'none';
  @property({ type: Boolean }) toolbar = true;
  @property({ type: Boolean }) statusbar = true;
  @property({ type: String }) viewrect = '';
  @property({ type: String }) nameddest = '';

  _blob: Blob | undefined = undefined;
  @property({ type: Object })
  get blob() {
    return this._blob;
  }
  set blob(value: Blob | undefined) {
    log('set blob');
    this._url = undefined;
    this._blob = value;
  }

  _url: string | undefined = undefined;
  @property({ type: String })
  get url() {
    return this._url;
  }
  set url(value: string | undefined) {
    log('set url');
    this._url = value;
    this._blob = undefined;
    safeFireAndForget(this.loadUrl());
  }

  eventBus: any;

  static styles = css`
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  `;

  @query('#container')
  container: HTMLDivElement;

  render() {
    if (!this._blob)
      return html``;

    const url = URL.createObjectURL(this._blob);
    const params = [];

    if (this.pageNumber) params.push(`page=${this.pageNumber}`);
    if (this.zoomLevel) params.push(`zoom=${this.zoomLevel}`);
    if (this.viewMode && !this.zoomLevel) params.push(`view=${this.viewMode}`);
    if (this.search) params.push(`search=${this.search}`);
    if (this.pageMode) params.push(`pagemode=${this.pageMode}`);
    if (!this.toolbar) params.push('toolbar=0');
    if (!this.statusbar) params.push('statusbar=0');
    if (this.viewrect) params.push(`viewrect=${this.viewrect}`);
    if (this.nameddest) params.push(`nameddest=${this.nameddest}`);

    const src =`${url}#${params.join('&')}`;
    this.shadowRoot.innerHTML = `<iframe 
      src="${src}"
      @load="${this._revokeBlobUrl}">
      ></iframe>`;
  }
  _revokeBlobUrl() {
    if (this._url)
      URL.revokeObjectURL(this._url);
  }
  async loadUrl() {
    log('loadUrl');
    if (!this.url) return;
    const response = await fetch(this.url!);
    this._blob = await response.blob();
    this.requestUpdate('blob')
  }
}
