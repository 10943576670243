import {css, html, LitElement, nothing} from 'lit';
import {customElement, property, query, state} from 'lit/decorators';
import {col, grow, hide, margin, padding, row} from "../styles";
import { extractTextAndScripts, extractTextFromScripts } from '../utils';
import {notificationService} from "../services/notificationService";
import { tableBroker } from "../services/TableBroker";

import Debug from 'debug';
import '@carbon/web-components/es/components/copy-button/index.js';
import '@carbon/web-components/es/components/icon-button/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import '@carbon/web-components/es/components/tag/index.js';
import Icon from '@carbon/web-components/es/icons/data-table/16.js';
import Save from '@carbon/web-components/es/icons/save/16.js';
import pdqHotkeyService from "../services/PdqHotkeyService";
import {pdqDiscoveryLayer} from "./_pdq-mixin";
import {TAG_TYPE} from "@carbon/web-components/es/components/tag/defs";
import { dataBroker } from '../services/DataBroker';

const log = Debug('pdq:flow:pdq-json');

@customElement('pdq-json')
@pdqDiscoveryLayer(log, Icon, 'pdq-json', 'rgba(255, 236, 83, 0.13)', TAG_TYPE.CYAN)
export class PdqJson extends LitElement {
    static styles = [hide, row, col, grow, padding, margin]
    @query('cds-textarea') textArea: HTMLTextAreaElement|undefined
    @property() error: string|null = null;
    @property() show: boolean = false;
    @property() json: string = '';
    @state() dataPreview = 'no data';

    static next_id = 0
    _id = 0
    constructor() {
        super();
        this._id = PdqJson.next_id++;
    }

    render()    {
        log(`${this.id} ${this.show} - render`)
        const slot = html`<slot id="pdq-json-${this._id}" class="hide ${this.error ? 'error' : ''}" name="json" @slotchange="${this._onSlotChange}"></slot>`
        const show = (this.show || pdqHotkeyService.showPdqData.currentValue());
        return html`
            ${slot}
            ${!!this.error ? html`<cds-inline-notification kind="error" title="PDQ Json" subtitle="${this.error} ${typeof this.error}"></cds-inline-notification>` : nothing}
            ${show ? html`
                <div class="col show ${this.error ? 'error' : ''} m-1">
                    ${this.renderToolBar(html`<cds-icon-button slot="tool-bar" @click="${this.save}" kind="primary" tooltip-text="Save">
                        ${Save({ slot: 'icon' })}
                    </cds-icon-button>`)}
                    <cds-textarea value="${this.json}">
                        <label slot="label-text">Config:</label>
                    </cds-textarea>
                </div>
                ` : nothing
            }`

    }
    async save(){
        log('save')
        if (!this.textArea)
            return
        try {
            const data = JSON.parse(this.textArea.value);
            await dataBroker.setDataSource(this.id, data)
            this.dataPreview = data
        }catch(e){
            log(`save error: ${e}`)
            notificationService.error(`pdq-json: ${this.id} - Failed to save... Error parsing JSON: ${e.message}`);
        }
    }

    _onSlotChange = async (event: Event)=> {
        console.log('slot change')
        log(`${this.id} - slot change`);
        const slot = event.target as HTMLSlotElement;
        const nodes = slot.assignedNodes({ flatten: true });
        const template = extractTextFromScripts(nodes);
        try {
            const json = JSON.parse(template);
            if (this.dataPreview !== json) {
                log(`${this.id} - slot change - setting data source`, json)
                await dataBroker.setDataSource(this.id, json)
                this.json = JSON.stringify(json, null, 2);
                this.dataPreview = this.json;
            }
            this.error = null
        }catch(e: any){
            log(`${this.id} - slot change error`);
            console.log(e)
            notificationService.error(`pdq-json: ${this.id} - Error parsing JSON: ${e.message}`);
            this.error = e.message ? e.message : e;
        }
    }
}



