import Debug from 'debug';
import { PerspectiveWorker, Table, View } from '@finos/perspective';
import perspective from '@finos/perspective/dist/esm/perspective.inline.js';
import {
  Subject, share, debounceTime, Subscription,
  ReplaySubject, Observable, shareReplay, tap
} from 'rxjs';
import { blobToArrayBuffer } from "../utils";
import { dataBroker } from './DataBroker';
import { HTMLPerspectiveViewerElement } from '@finos/perspective-viewer';
import { PdqPerspectiveViewer } from '../flow/pdq-perspective-viewer';

const log = Debug('pdq:services:TableBroker');

export const NO_TABLE_YET = Symbol('NO_TABLE_YET');
export const NO_DATASOURCE_YET = Symbol('NO_DATASOURCE_YET');
export const NO_INDEX_SETUP = Symbol('NO_INDEX_SETUP');

export class TableBroker {
  private _worker = perspective.shared_worker()

  private _tables = new Map<string, TableMeta>();

  private _tablesChanged = new Subject<string[]>()
  private _tableRemoved = new Subject<string>()
  private _tableAdded = new Subject<string>()
  private _tableChanged = new Subject<string>()

  tablesChanged = this._tablesChanged.pipe(debounceTime(30), share());
  tableRemoved = this._tableRemoved.pipe(share());
  tableAdded = this._tableAdded.pipe(debounceTime(30),share());
  tableChanged = this._tableChanged.pipe(share());

  constructor() {
    log('TableBroker created')
    dataBroker.dataSourceRemoved.subscribe(this._purgeTables)
  }

  async createTableWithSchema(name: string, source: string, schema: any, index: string) {
    const existing = this._tables.get(name);
    if (existing && existing.datasource === source) {
      log(`Table ${name} already registered... skipping add`)
      return existing.sharedObservable
    }


    log(`Creating table ${name}`)
    const meta = this._setupTable(name)
    if (meta.table !== NO_TABLE_YET)
      return ;
    meta.table = await this._worker.table(schema, { index });
    const ds = await dataBroker.getObservableDataSource(source);
    this.subscribe(meta, ds, name, source, index);
  }

  async setTable(name: string, source: string, index: string) {
    const existing = this._tables.get(name);
    if (existing && existing.datasource === source) {
      log(`Table ${name} already registered... skipping add`)
      return existing.sharedObservable
    }
    // console.group('setTable')
    log(`Adding table ${name} from ${source}`);
    try {
      const ds = await dataBroker.getObservableDataSource(source);
      const meta = this._setupTable(name)
      this.subscribe(meta, ds, name, source, index);
      return meta.sharedObservable
    } finally {
      // console.groupEnd()
    }
  }

  private subscribe(meta: TableMeta, ds: Observable<any>, name: string, source: string, index: string) {
    meta.subscription = ds
    .pipe(tap(i => log(`TAP: Adding table ${name} from ${source}`)))
    .subscribe(async i => {
      log(`data updated... updating table ${name}`);
      const data = await this.convertData(i);
      if (meta.table === NO_TABLE_YET) {
        log(`Creating table ${name}`);
        const table = await this._worker.table(data, { index });
        meta.table = table;
        meta.datasource = source;
        meta.references.add({
          from: { type: 'data', name: source },
          to: { type: 'table', name }
        });
        meta.index = index;
        this._tableAdded.next(name);
        this._tablesChanged.next([...this._tables.keys()]);
        meta.subject.next(table);
        return;
      }
      const table = meta.table as Table;
      if (index) {
        log(`Updating table ${name}`);
        table.update(i);
        meta.subject.next(table);
      } else {
        log(`Replacing table ${name}`);
        await table.replace(i);
        meta.subject.next(table);
      }
      this._tableChanged.next(name);
    });
  }

  getTable(name: string){
    const meta = this._setupTable(name)
    return meta.sharedObservable
  }
  getTableMeta(name: string){
    const meta = {... this._tables.get(name) }
    delete meta.subject
    return meta
  }
  async convertData(data: any){
    if (data instanceof Blob) {
      return await blobToArrayBuffer(data);
    } else {
      return data;
    }
  }

  private _setupTable(name: string){
    let meta = this._tables.get(name)
    if (meta)
      return meta
    log(`Creating table placeholder ${name}`);
    const subject = new ReplaySubject<Table>(1)
    meta = {
      id: name,
      index: NO_INDEX_SETUP,
      table: NO_TABLE_YET,
      datasource: NO_DATASOURCE_YET,
      subscription: Subscription.EMPTY,
      references: new Set(),
      subject: subject,
      sharedObservable: subject.pipe(shareReplay(1))
    } as TableMeta
    this._tables.set(name, meta)
    this._tableAdded.next(name);
    return meta
  }
  private async _purgeTables(removedDataSource: string) {
    for (const [key, value] of this._tables) {
      if (value.datasource == removedDataSource) {
        if (value.table !== NO_TABLE_YET)
          await value.table.delete()
        value.subject.complete()
        value.subscription.unsubscribe()

        this._tables.delete(key)
        this._tableRemoved.next(key)
        this._tablesChanged.next([...this._tables.keys()])
      }
    }
  }
  private _viewers = new Map<string, PdqPerspectiveViewer>();
  addViewer (id: string, v:PdqPerspectiveViewer) {
    this._viewers.set(id, v)
  }
  getViewer(id:string){
    return this._viewers.get(id)
  }
  removeViewer (id: string) {
    this._viewers.delete(id)
  }
  info(){
    console.log('TABLES')
    console.table([...this._tables.entries()].map(i => ({...i[1], key: i[0],})), ['id', 'index', 'references', 'datasource'])
    console.log('VIEWERS')
    console.table([...this._viewers.entries()].map(i => ({element:i[1], key: i[0],})), ['key', 'element'])
  }
}

export const tableBroker = new TableBroker();

export interface Source {
  type: 'data'|'table'|'view'
  name: string
}
export interface Reference {
  from: Source
  to: Source
}
export interface TableMeta {
  id: string
  index: string | typeof NO_INDEX_SETUP
  table: Table | typeof NO_TABLE_YET
  datasource: string | typeof NO_DATASOURCE_YET
  subscription: Subscription
  references: Set<Reference>

  subject: Subject<Table>
  sharedObservable: Observable<Table>
}

export interface ViewMeta {
  id: string,
  table: Table | undefined,
  view: ReplaySubject<View>,
  options: any,
  references: Set<string>,
  parents: Set<string>,
  dependents: Set<string>
}