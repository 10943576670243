import { customElement, property } from 'lit/decorators';
import { css, html, LitElement, nothing } from 'lit';
import '@carbon/web-components/es/components/modal';
import { PropertyValues } from '@lit/reactive-element';
import { googleApi } from './googleApi';
import Debug from 'debug';
import { extractTextFromScripts } from '../utils';

const log = Debug('pdq:google:pdq-google-api');

@customElement('pdq-google-api')
export class PdqGoogleApiElement extends LitElement {

  static styles = css``;

  render() {
    return html`<slot name="services" style="display: none" @slotchange=${this._onSlotChange}></slot>`;
  }

  _onSlotChange = async (event: Event)=> {
    log(`${this.id} - slot change`);
    const slot = event.target as HTMLSlotElement;
    const nodes = slot.assignedNodes({ flatten: true });
    const text = extractTextFromScripts(nodes);
    try {
      const services = JSON.parse(text)
      log(`loading services`)
      await googleApi.load(services);
      log(`loaded services`)
    } catch(ex) {
      log(`Failed to load services... ${ex}`);
    }
  }
}
