import {customElement, property, query, state} from "lit/decorators";
import {css, html, LitElement, nothing} from "lit";
import {col, grow, hide, margin, padding, paddingAll, row} from "../styles";
import {extractTextAndScripts} from "../utils";
import { tableBroker } from "../services/TableBroker";
import {notificationService} from "../services/notificationService";
import Debug from "debug";
import pdqHotkeyService from "../services/PdqHotkeyService";
import Icon from "@carbon/web-components/es/icons/data--view/16";
import Save from '@carbon/web-components/es/icons/save/16.js';
import '@carbon/web-components/es/components/tag/index.js';
import {pdqDiscoveryLayer} from "./_pdq-mixin";
import {TAG_TYPE} from "@carbon/web-components/es/components/tag/defs";
import { Table } from '@finos/perspective';
const log = Debug('pdq:flow:pdq-view');

@customElement('pdq-view')
@pdqDiscoveryLayer(log, Icon, 'pdq-view', 'rgba(83,236,255,0.13)', TAG_TYPE.BLUE)
export class PdqView extends LitElement {
    static styles = [hide, row, col, grow, padding, margin]

    @property() table: string | undefined = undefined;
    @property() error: string | null = null;
    @property() show: boolean = false;
    @state() dataPreview: string = 'loading...';
    @state() config: string = 'loading...';
    @query('cds-textarea') textArea: HTMLTextAreaElement|undefined;
    static _nextId = 0;
    _id= PdqView._nextId++;
    constructor() {
        super();
        pdqHotkeyService.showPdqData.subscribe((show: boolean) => {this.requestUpdate()})
    }

    render() {
        log(`pdq-data: ${this.id} ${this.show} - render`)

        const slot = html`<slot id="pdq-view-${this._id}" class="hide"  @slotchange="${this._onSlotChange}"></slot>`
        const show = this.show|| pdqHotkeyService.showPdqData.currentValue()
        return html`
            ${slot}
            ${show ? html`
            <div class="show col ${this.error ? 'error' : ''} m-1">
                ${this.renderToolBar(html`<cds-icon-button slot="tool-bar" @click="${this.save}" kind="primary" tooltip-text="Save">
                        ${Save({ slot: 'icon' })}
                    </cds-icon-button>`)}
                <cds-textarea value="${this.config}">
                    <label slot="label-text">Config:</label>
                </cds-textarea>
            </div>` : nothing}
        `;

    }
    async save(){
        log('save')
        if (!this.textArea)
            return
        this._update(this.textArea.value)
    }

    toggle(){
        this.show = !this.show;
        log(`${this.id} - toggle`, this.show)
    }
    async _onSlotChange(event: Event) {
        log(`${this.id} - slot change`)
        const slot = event.target as HTMLSlotElement;
        const nodes = slot.assignedNodes({flatten: true});
        const {template, scripts} = extractTextAndScripts(nodes);
        if (template)
            await this._update(template);
        else if (scripts.length == 1)
            await this._update(scripts[0]);
    }

    private async _update(template: string) {
        if (!this.table) {
            notificationService.error(`pdq-view: ${this.id} - table attribute is required`);
            throw new Error(`pdq-view: ${this.id} - table attribute is required`);
        }

        try {
            const json = JSON.parse(template);

            const view = await tableBroker.getOrCreateView(this.id, this.table, json);


            this.config = JSON.stringify(json, null, 2);
            this.dataPreview = `<pdq-view id="${this.id}" table="${this.table}"><template>\n${this.config}\n</template></pdq-view>`
            this.error = null
        } catch (e: any) {
            notificationService.error(`pdq-view#${this.id}|Error parsing JSON: \n${e.message}`);
            this.error = e.message;
        }
    }

}


