import { BlobMeta, blobRegistry } from './BlobRegistry';
import Debug from 'debug';
import { Mutex } from '../utils';
const log = Debug('pdq:services:BlobCache');
const defaultExpires = 1000 * 60 * 60 * 24 * 20; // 20 day
export class BlobCache {
  #cache = new Map<string, {
    key: string,
    url: string,
    expiresAt: Date
  }>();
  #keyMutexes = new Map<string, Mutex>();

  async get(key: string, func: () => Promise<Blob>, expires: number = defaultExpires): Promise<CacheBlob> {
    log(`requesting blob with key ${key}, expires in ${expires}ms`)
    const mutex = this.getKeyMutex(key);
    const unlock = await mutex.lock()
    try {
      let cacheKey = this.#cache.get(key);
      if (cacheKey) {
        const blob = blobRegistry.blobsByUrl.get(cacheKey.url);
        if (blob) {
          log(`returning cached blob with key ${key}`)
          return {
            ...blob,
            ...cacheKey
          }
        }
      }

      log(`blob with key ${key} does not exist in cache... fetching new blob`)
      const newBlob = await func();
      const meta = await blobRegistry.registerBlob(newBlob) as BlobMeta;
      cacheKey = { key, url: meta.url, expiresAt: new Date(Date.now() + expires) };
      this.#cache.set(key, cacheKey);

      setTimeout(() => {
        log(`removing expired blob with key ${key}`)
        this.#cache.delete(key);
        meta.unregister();
      }, expires);
      log(`returning newly cached blob with key ${key} expires at ${cacheKey.expiresAt}`)
      return {
        ...meta,
        ...cacheKey
      }
    }finally {
      unlock();
      if(!mutex.hasQueue())
        this.#keyMutexes.delete(key)
    }
  }

  getKeyMutex(key: string): Mutex {
    let mutex = this.#keyMutexes.get(key);
    if (!mutex) {
      mutex = new Mutex('BlobCache');
      this.#keyMutexes.set(key, mutex);
    }
    return mutex;
  }
}

export interface CacheBlob extends BlobMeta {
  expiresAt: Date
}

export const blobCache = new BlobCache();
