import {LitElement, html, css} from 'lit';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import validator from '@rjsf/validator-ajv8';
import Debug from 'debug';
import { customElement } from 'lit/decorators.js';
import {  extractTextFromScripts } from '../utils';
import { property, query } from 'lit/decorators';

const default_schema = {};

const log = Debug('pdq:forms:pdq-schema-form');
@customElement('pdq-schema-form')
export class PdqSchemaForm extends LitElement {
  static styles = css`
    legend {
        color: var(--cds-text-primary, #161616);
    }
    #react-root {
        color: var(--cds-text-primary, #161616);
        background: var(--cds-background, white);
    }
      
  `;
    @property({ type: Object }) schema = default_schema;
    @property({ type: Object }) uiSchema = {};
    @property({ type: Object })
    get formData() {
      return this._formData;
    };
    set formData(value) {
      const oldValue = this._formData;
      console.log(this._formData, value);
      if (typeof value === 'string')
        try {
          value = JSON.parse(value);
        }catch(e){
          console.warn(`Failed to parse formData: ${value}`, e);
          log(`formData is a string but is not valid json, failed to parse formData: ${value}`)
          value = {}
        }
      this._formData = value;
      this.requestUpdate('formData', oldValue);
    }
    @property({ type: String }) theme = 'bootstrap-3';
    @property({ type: Boolean }) showErrorList = false;
    @property({ type: String }) acceptcharset = 'UTF-8';
    @property({ type: String }) action = '';
    @property({ type: String }) autoComplete = 'off';
    @property({ type: String }) className = '';
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) readonly = false;
    @property({ type: String }) enctype = 'application/json';
    @property({ type: Object }) extraErrors = {};
    @property({ type: Boolean }) extraErrorsBlockSubmit = false;
    @property({ type: Object }) fields = {};
    @property({ type: Boolean }) focusOnFirstError = true;
    @property({ type: Object }) formContext = {};
    @property({ type: String }) id = '';
    @property({ type: String }) idPrefix = 'root';
    @property({ type: String }) idSeparator = '_';
    @property({ type: Boolean }) liveOmit = false;
    @property({ type: Boolean }) liveValidate = false;
    @property({ type: String }) method = 'post';
    @property({ type: String }) name = '';
    @property({ type: Boolean }) noHtml5Validate = false;
    @property({ type: Boolean }) noValidate = false;
    @property({ type: Boolean }) omitExtraData = false;
    @property({ type: String }) tagName = 'form';
    @property({ type: String }) target = '';


    @query('#react-root')
    private reactRoot!: HTMLElement;

    private root?: ReturnType<typeof createRoot>;
    private reactElement?: any;

    private _formData: any;

    async firstUpdated() {
        if (!this.root) {
          this.root = createRoot(this.reactRoot);
        }

        const Form = await this.getThemedForm(this.theme);
        this.reactElement = createElement(Form, {
          schema: this.schema,
          uiSchema: this.uiSchema,
          validator,

          showErrorList: this.showErrorList,
          acceptcharset: this.acceptcharset,
          action: this.action,
          autoComplete: this.autoComplete,
          className: this.className,
          disabled: this.disabled,
          readonly: this.readonly,
          enctype: this.enctype,
          extraErrors: this.extraErrors,
          extraErrorsBlockSubmit: this.extraErrorsBlockSubmit,
          fields: this.fields,
          focusOnFirstError: this.focusOnFirstError,
          formContext: this.formContext,
          formData: this.formData,
          id: this.id,
          idPrefix: this.idPrefix,
          idSeparator: this.idSeparator,
          liveOmit: this.liveOmit,
          liveValidate: this.liveValidate,
          method: this.method,
          name: this.name,
          noHtml5Validate: this.noHtml5Validate,
          noValidate: this.noValidate,
          omitExtraData: this.omitExtraData,
          tagName: this.tagName,
          target: this.target,

          onChange: this.onChange,
          onError: this.onError,
          onSubmit: this.onSubmit,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
        });
        this.root.render(this.reactElement);
    }

    updated(changedProperties: Map<string | number | symbol, unknown>) {
      super.updated(changedProperties);
      if (this.reactElement && changedProperties.size > 0) {
        const newProps = Array.from(changedProperties.keys()).reduce((props: any, key) => {
          props[key as string] = (this as any)[key];
          return props;
        }, {});
        this.root?.render(
          createElement(this.reactElement.type, {
            ...this.reactElement.props,
            ...newProps,
          })
        );
      }
    }
    render() {
        return html`
            <slot style="display: none" name="schema" @slotchange="${this._onSchemaSlotChange}"></slot>
            <slot style="display: none" name="uiSchema" @slotchange="${this._onUiSchemaSlotChange}"></slot>
            <slot style="display: none" name="formData" @slotchange="${this._onFormDataSlotChange}"></slot>
            <link id="theme-style" rel="stylesheet">
            <div id="react-root"></div>
        `;
    }

    private onError = (errors: any) => {
        this.dispatchEvent(new CustomEvent('formError', { detail: { errors } }));
    }
    private onSubmit = ({ formData }: { formData: any }, event: Event) => {
      this.dispatchEvent(new CustomEvent('formSubmit', { detail: { formData, sourceEvent: event } }));
    }
    private onChange = ({ formData }: { formData: any }, id: any) => {
      this._formData = formData;
      this.dispatchEvent(new CustomEvent('formChange', { detail: { formData, id } }));
    }
    private onFocus = (event: Event) => {
        this.dispatchEvent(new CustomEvent('formFocus', { detail: { sourceEvent: event } }));
    }
    private onBlur = (event: Event) => {
        this.dispatchEvent(new CustomEvent('formBlur', { detail: { sourceEvent: event } }));
    }
    private async getThemedForm(theme: string): Promise<any> {
        const cssLink = this.shadowRoot?.querySelector('#theme-style') as HTMLLinkElement;
        let module;
        switch (theme) {
            case 'bootstrap-3':
                cssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css';
                module = await import('@rjsf/core');
                return module.default;
            case 'bootstrap-4':
                cssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css';
                module = await import('@rjsf/bootstrap-4');
                return module.default;
            case 'antd':
                cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/antd/4.6.6/antd.min.css';
                module = await import('@rjsf/antd');
                return module.default;
            default:
                cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css';
                console.warn(`theme '${theme}' not found... valid themes are: bootstrap-3, bootstrap-4, antd`);
                module = await import('@rjsf/core');
                return module.default;
        }
    }
    private _processSlot(event: Event, slotName: 'schema'|'uiSchema'|'formData'){
        let templateForLog = 'failed to read content'
        try {
            const slot = event.target as HTMLSlotElement;
            const nodes = slot.assignedNodes({ flatten: true });
            const  template= extractTextFromScripts(nodes);
            templateForLog = template
            const oldValue = this[slotName]
            this[slotName] = JSON.parse(template);
            this.requestUpdate(slotName, oldValue);
            log(`slot  ${slotName} content changed to ${template}`);
        } catch (e) {
            console.error(`Failed to parse form ${slotName}, \n${templateForLog}`, e);
        }
    }
    private _onSchemaSlotChange(event: Event) {
        this._processSlot(event, 'schema');
    }
    private _onUiSchemaSlotChange(event: Event) {
        this._processSlot(event, 'uiSchema');
    }
    private _onFormDataSlotChange(event: Event) {
        this._processSlot(event, 'formData');
    }
}
