import {customElement, property} from "lit/decorators";
import {css, html, LitElement} from "lit";
import '@carbon/web-components/es/components/modal';

@customElement('pdq-confirm-dialog')
export class PdqConfirmDialog extends LitElement {
    @property({type: Array})
    actions: string[] = ['Confirm', 'Cancel']; // Default actions

    @property({type: String})
    header: string = 'Confirm Action'; // Default header text

    @property({type: String})
    message: string = 'Are you sure you want to proceed?'; // Default message

     static styles = css`    
      :host {
        --cds-layer: rgba(244,244,244);
        --cds-text-primary: #161616;
      } 
     `

    private buttonClicked(action: string) {
        this.dispatchEvent(new CustomEvent('action-selected', {detail: {action}, bubbles: true, composed: true}));
        this.remove(); // Remove the modal from the DOM
    }

    render() {
        return html`
            <cds-layer>
            <cds-modal open>
                <cds-modal-header>
                    <cds-modal-label>Confirmation Required</cds-modal-label>
                    <cds-modal-heading>${this.header}</cds-modal-heading>
                </cds-modal-header>
                <cds-modal-body><p>
                    ${this.message}
                </p></cds-modal-body>
                <cds-modal-footer>
                    ${this.actions.map((action) =>
                            html`<cds-modal-footer-button class="button" @click="${() => this.buttonClicked(action)}">${action}</cds-modal-footer-button>`
                    )}
                </cds-modal-footer>
            </cds-modal>
            </cds-layer>
        `;
    }
}