import Debug from 'debug';
import { defer } from '../utils';
import { GAPI } from './interfaces';
import { BehaviorSubject, delay, filter, first, firstValueFrom, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const PDQ_GOOGLE_STORAGE_KEY = 'pdq-google-' + location.host;
const GOOGLE_GIS_URI = 'https://accounts.google.com/gsi/client'
let __gis: Gsi | undefined = undefined;
const log = Debug('pdq:google:GoogleAuth');

export class GoogleAuth {
  loggedIn = new BehaviorSubject<boolean>(false);
  tokenClient = new BehaviorSubject<GoogleTokenClient|undefined>(undefined);
  token = new BehaviorSubject<GoogleAuthToken>(new GoogleAuthToken(this.tokenClient));
  private unsub = Subscription.EMPTY;

  constructor() {
        this.unsub = this.token.pipe(switchMap(i => i.loggedIn)).subscribe((i) => {
      log(`loggedIn changed to ${i}`)
      this.loggedIn.next(i);
      if (i) {
        document.body.classList.add('pdq-google-logged-in')
      } else {
        document.body.classList.remove('pdq-google-logged-in')
      }
    })

  }
  private async getGIS(): Promise<Gsi> {
    if (!__gis) {
      const gisSoon = defer();
      const script = document.createElement('script');
      script.src = GOOGLE_GIS_URI;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        __gis = (window as any).google
        gisSoon.resolve(__gis);
      };
      script.onerror = (e) => {
        gisSoon.reject(e)
      };
      document.head.appendChild(script);
      await gisSoon.promise;
    }
    return __gis!;
  };
  async setupAndLogin(clientId: string, scopes: string[], attemptAutoLogin: boolean) {
    log(`setupAndLogin: ${clientId} ${scopes}`)
    const token = this.token.value!
    const gsi = await this.getGIS();

    if (!token.isLoginRequired(clientId, scopes)) {
      log('login not required...')
      return
    }

    token.init(clientId, scopes);
    const tokenClient = gsi.accounts.oauth2.initTokenClient({
      client_id: token.clientId,
      scope: token.scope,
      callback: (response) => {
        token.handleResponse(response, this.fetchUserDetails)
        this.token.next(token);
      },
      error_callback: (error) => {
        log(`google auth error, ${error}`);
      }
    })
    this.tokenClient.next(tokenClient);
    this.token.next(token);

    if(attemptAutoLogin) {
      log('attempting auto log in')
      tokenClient.requestAccessToken();
    }
  }
  async fetchUserDetails(accessToken: string) {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const userDetails: GoogleUser = await response.json();
    return userDetails;
  }
  async login(){
    const oathClient = this.tokenClient.value;
    if (oathClient) {
      log('login requested - tokenClient invited, requesting accessToken')
      return oathClient.requestAccessToken();
    }

    log('login requested - tokenClient is not in-place, calling setupAndLogin')
    const token = this.token.value!
    await this.setupAndLogin(token.clientId, token.scopes, true);
  }
  async getToken(resp: GoogleResponse) {
    log('google getToken()', resp);
    const err = resp.result;

    if (err.error?.code != 401 && err.error?.code != 403) {
      debugger
      throw new Error(`Google API errror. ${err.error?.code} ${err.error?.message}`);
    }
    return this.token.value?.access_token
  }
  async logout() {
    log('logout');
    const token = this.token.value!;
    if (token.loggedIn.value) {
      const gis = await this.getGIS();
      gis.accounts.oauth2.revoke(token.access_token, token.loggedOut);
    }
  }
  async setToken(gapi: GAPI) {
    let token = this.token.value
    if (!token?.loggedIn.value) {
      log('setToken... waiting for valid token')
      await firstValueFrom(this.loggedIn
        .pipe(filter(i => i), first(), delay(100)))
    }
    log('setToken... access_token available, setting on gapis')
    gapi.client.setToken({access_token: token.access_token})
  }
}
const atlog = Debug('pdq:google:GoogleAuthToken');

export class GoogleAuthToken {
  loggedIn = new BehaviorSubject<boolean>(false);

  clientId: string = '';
  access_token: string = ''
  authuser: string = ''
  expires_in: number = 0
  expires_at: Date = new Date(1970,0,1)
  hd: string  = ''
  prompt: string = ''
  scope:string = ''
  token_type:string = ''
  user: GoogleUser | undefined = undefined


  get scopes() {
    return this.scope.split(' ')
  }

  constructor(private tokenClient: BehaviorSubject<GoogleTokenClient|undefined>) {
    this.loggedIn.subscribe(i => atlog(`loggedIn=${i}`));
    this.load()
  }
  init(clientId: string, scopes: string[]) {
    atlog(`init`)
    this.clientId = clientId
    this.access_token = ''
    this.authuser = ''
    this.expires_in = 0
    this.expires_at = new Date(1970,0,1)
    this.hd  = ''
    this.prompt = ''
    this.scope = this.getNormalizedScopes(scopes)
    this.token_type= ''
    this.updateIsLoggedIn()
  }
  load(){
    const storedToken = localStorage.getItem(PDQ_GOOGLE_STORAGE_KEY);
    if (!storedToken) {
      atlog('no stored token')
      this.updateIsLoggedIn()
      return
    }
    try {
      const v = JSON.parse(atob(storedToken)) as any;
      if (v.access_token) {
        this.access_token = v.access_token;
        this.expires_in = parseInt(v.expires_in);
        this.expires_at = new Date(v.expires_at);
        this.clientId = v.client_id;
        this.authuser = v.authuser;
        this.hd = v.hd;
        this.prompt = v.prompt;
        this.scope = this.getNormalizedScopes(v.scope.split(' '));
        this.token_type = v.token_type;
        this.user = v.user;
      }
      atlog('loaded stored token, expires at', this.expires_at)
    }catch(e){
      atlog('failed to parse stored token')
    }
    this.updateIsLoggedIn()

  }
  save(){
    const storedToken = {
      access_token: this.access_token,
      expires_in: this.expires_in,
      expires_at: this.expires_at,
      client_id: this.clientId,
      authuser: this.authuser,
      hd: this.hd,
      prompt: this.prompt,
      scope: this.getNormalizedScopes(this.scope.split(' ')),
      token_type: this.token_type,
      user: this.user
    }
    localStorage.setItem(PDQ_GOOGLE_STORAGE_KEY, btoa(JSON.stringify(storedToken)))
    atlog('saved token')
  }

  async handleResponse(response: GoogleTokenAuthResponse, fetchUserDetails: (accessToken: string) => Promise<GoogleUser>) {
    if (!response.access_token) {
      atlog(`no access token in response`)
      this.updateIsLoggedIn()
      return
    }
    this.access_token = response.access_token;
    this.expires_in = response.expires_in;
    this.expires_at = new Date(Date.now() + response.expires_in * 1000)
    this.authuser = response.authuser;
    this.hd = response.hd;
    this.prompt = response.prompt;
    this.token_type = response.token_type;

    atlog('updating user details')
    this.user = await fetchUserDetails(response.access_token)
    this.save()
    this.updateIsLoggedIn()

    setTimeout(() => {
      atlog('token timeout')
      const client = this.tokenClient.value
      if (client) {
        atlog('auto requesting access token')
        this.tokenClient.value?.requestAccessToken();
      }else {
        atlog('token expired, click is required to init login, so logging out')
        this.init(this.clientId, this.scopes);
      }
    }, (this.expires_in * 1000) - 3000)
    atlog(`login success, expires at ${this.expires_at}`)
  }
  loggedOut = () => {
    atlog('logged out')
    this.init(this.clientId, this.scopes)
    this.save()
  }
  isLoginRequired(clientId: string, scopes: string[]) {
    atlog('isLoginRequired')

    if (!this.loggedIn.value)
      return true

    if (this.clientId != clientId)
      return true

    if (!this.doNormalizedScopesMatch(scopes))
      return true

    return false
  }
  private updateIsLoggedIn(){
    this.loggedIn.next(!!this.access_token && this.expires_at > new Date())
  }
  private doNormalizedScopesMatch(scopes: string[]) {
    const nScopes = this.getNormalizedScopes(scopes);
    return nScopes === this.scope
  }
  private getNormalizedScopes(scopes: string[]) {
    const scopesSet = new Set([
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
      ...scopes.map(i => i.trim())
    ])
    return [...scopesSet].join(' ')
  }
}

interface Gsi {
  accounts: {
    id: {
      initialize: (config: { client_id: string, callback: (response: CredentialResponse) => void }) => void;
      renderButton: (parent: HTMLElement, options: GsiButtonConfiguration) => void;
      prompt: () => void;
    }
    oauth2: {
      initTokenClient: (config: {
        scope: string;
        error_callback: (error: any) => void;
        callback: (response: GoogleTokenAuthResponse) => void;
        client_id: string
      }) => GoogleTokenClient,
      revoke: (token: string, callback: () => void) => void
    }
  };
}
interface GoogleTokenClient {
  requestAccessToken: () => void
}
interface GoogleResponse{
  body: string
  headers: {[key: string]: string}
  status: number
  statusText: string
  result: GoogleErrorResponse
}
interface GoogleErrorResponse {
  error?: GoogleErrorResponse
}
interface GoogleErrorResponse {
  code: number;
  message: string;
  errors: GoogleErrorDetail[];
  status: string;
}
interface GoogleErrorDetail {
  message: string;
  domain: string;
  reason: string;
}
interface CredentialResponse {
  credential: string;
  select_by: string;
  clientId: string;
  client_id: string;
}
interface GsiButtonConfiguration {
  type: string;
  theme: string;
  size: string;
  text?: string;
  shape?: string;
  logo_alignment?: string;
  width?: string;
}
interface GoogleTokenAuthResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  authuser: string;
  hd: string;
  prompt: string;
}
interface GoogleUser {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
}

export const googleAuth = new GoogleAuth();
