import {customElement} from "lit/decorators";
import { css, html, LitElement } from 'lit';
import {col, grow, hide, margin, padding, row} from "../styles";

import Debug from "debug";
import pdqHotkeyService from "../services/PdqHotkeyService";
import ViewOff16 from '@carbon/web-components/es/icons/view--off/16.js';
import View16 from '@carbon/web-components/es/icons/view/16.js';
import Table16 from '@carbon/web-components/es/icons/table/16.js';

import '@carbon/web-components/es/components/tag/index.js';
const log = Debug('pdq:flow:toolbar');

@customElement('pdq-toolbar')
export class PdqToolbar extends LitElement {
    static styles = [hide, row, col, grow, padding, margin, css`
        :host {
            position: fixed;
            bottom: 16px;
            left: 16px;
            z-index: 1001;
        }
    `]
    icon = View16;

    constructor() {
        super();
        pdqHotkeyService.showPdqData.subscribe((show: boolean) => {
            this.icon = show ? ViewOff16 : View16;
            this.requestUpdate()
        })
    }

    render() {
        return html`
            <cds-icon-button kind="secondary" @click="${() => pdqHotkeyService.toggleView()}" tooltip-position="bottom" tooltip-text="test">
              <span slot="tooltip-content">Toggle Config Layer</span>
              ${this.icon({slot: 'icon'})}
            </cds-icon-button>
            <cds-icon-button kind="secondary" @click="${() => pdqHotkeyService.toggleModel()}">
              <span slot="tooltip-content" tooltip-position="bottom" >Toggle Model Layer</span>
              ${Table16({slot: 'icon'})}
            </cds-icon-button>
        `
    }
}
