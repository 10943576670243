import { customElement, property, query } from 'lit/decorators';
import { css, html, LitElement } from 'lit';
import { tableBroker, TableMeta, ViewMeta } from '../services/TableBroker';

import Debug from 'debug';
import pdqHotkeyService from '../services/PdqHotkeyService';

import '@carbon/web-components/es/components/button/button.js';
import Icon from '@carbon/web-components/es/icons/close/20.js';

type TableOrViewMeta = TableMeta | ViewMeta;
const log = Debug('pdq:flow:pdq-data-model');

@customElement('pdq-data-model')
export class PdqDataModel extends LitElement {
  static styles = [
    css`
      :host {
          position: fixed;
          top: -10000px;
          left: -10000px;
          right: 10000px;
          bottom: 10000px;
          background-color: rgba(255, 255, 255, 0.9);
          z-index: 1000;
      }
      #pdq-data-model-open-button {
          position: fixed;
          top: 0;
          right: 0;
          z-index: 1001;
          display: var(--display, 'none')!important;
      }
      svg {
          shape-rendering: auto;
      }
      text {
          text-rendering: optimizeLegibility;
          font-smoothing: antialiased;
      }
      .node circle {
          fill: #fff;
          stroke: steelblue;
          stroke-width: 3px;
      }
      .node text { font: 16px sans-serif; }
      .link {
          fill: none;
          stroke: #ccc;
          stroke-width: 2px;
      }
  `
  ];

  model: any = [];
  @property() show = false;
  @query('svg#diagram') private svg!: SVGElement;
  private d3: any;

  constructor() {
    super();
    log('present on the page')

    const defaultOverflow = document.body.style.overflow
    const show = () => {
      log('Showing data model')
      this.style.zIndex = "1000";
      this.style.visibility = "visible";
      this.style.inset = "0px";
      this.style.setProperty('--display', 'block')
      document.body.style.overflow = 'hidden';
      this.updateSvg()
    }
    const hide =  () => {
      log('Hiding data model')
      this.style.zIndex = "-1000";
      this.style.visibility = "hidden";
      this.style.top = "-10000px";
      this.style.left = "-10000px";
      this.style.right = "10000px";
      this.style.bottom = "10000px";
      this.style.setProperty('--display', 'none')
      document.body.style.overflow = defaultOverflow;
    }
    hide()
    tableBroker.tablesChanged.subscribe(i => this.model = i);
    pdqHotkeyService.showPdqModel.subscribe((i: boolean) => i ? show() : hide());
  }
  render() {
    return html`
      <cds-icon-button id="pdq-data-model-open-button" kind="danger" @click=${() => pdqHotkeyService.toggleModel()}>
        <span slot="tooltip-content"> Toggle Visibility </span>
        ${Icon({slot: 'icon'})}
      </cds-icon-button>
      <svg id="diagram"/> `;
  }
  private async updateSvg() {
    if (!this.d3) {
      this.d3 = await import('/node_modules/d3/dist/d3.js');
    }
    if (!this.d3 || !this.svg) {
      log(`not rendering, requirements not met: d3=${!!this.d3}, svg=${!!this.svg}`)
      return;
    }
    const model = this.model;
    log('Updating SVG', model);

    try {
      const margin = { top: 20, right: 290, bottom: 30, left: 90 },
        width = window.innerWidth - margin.left - margin.right,
        height = window.innerHeight - margin.top - margin.bottom;

      const d3 = this.d3;
      const svg = this.d3.select(this.svg);
      svg.html("");
      const g = svg.append("g");

      svg.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("preserveAspectRatio", "xMidYMid meet");

      g.attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr('width', width)
      .attr('height', height);

      const zoom = d3.zoom()
      .on('zoom', (event: any) => {
        g.attr('transform', event.transform);
      });
      svg.call(zoom)

      const drag = d3.drag()
      .on('drag', function(event: any) {
        let dx = event.dx,
          dy = event.dy,
          currentTransform = d3.zoomTransform(svg.node());
        svg.call(zoom.transform, currentTransform.translate(dx, dy));
      });
      svg.call(drag);


      const dataTree = buildTree(model as any)

      if (!dataTree)
        return;

      const data = d3.hierarchy(dataTree);
      const treemap = d3.tree().size([height, width]);

      let nodes = d3.hierarchy(data, (d: any) => d.children);
      nodes = treemap(nodes);


      const link = g
        .selectAll(".link")
        .data(nodes.descendants().slice(1))
        .enter().append("path")
        .attr("class", "link")
        .style("stroke", (d: any) => d.data.level)
        .attr("d", (d: any) => {
          return "M" + d.y + "," + d.x
            + "C" + (d.y + d.parent.y) / 2 + "," + d.x
            + " " + (d.y + d.parent.y) / 2 + "," + d.parent.x
            + " " + d.parent.y + "," + d.parent.x;
        });

      // adds each node as a group
      const node = g
        .selectAll(".node")
        .data(nodes.descendants())
        .enter().append("g")
        .attr("class", (d: any) => "node" + (d.children ? " node--internal" : " node--leaf"))
        .attr("transform", (d: any) => "translate(" + d.y + "," + d.x + ")");

      // adds the circle to the node
      node.append("circle")
      // .attr("r", (d: any) => d.data.value)
      // .style("stroke", (d: any) => d.data.type)
      // .style("fill", (d: any) => d.data.level);

      // adds the text to the node
      node.append("text")
      //.attr("dy", ".35em")
      //.attr("x", (d: any) => 0)
      //.attr("y", (d: any) => d.children?.length > 0 ? -10 : 0)
      .style("text-anchor", (d: any) => d.children ? "end" : "start")
      .text((d: any) => {
        return d.data.data.name;
      });
      log('Updated SVG')
    }catch(e){
      log(`Failed to update SVG: ${e}`)
    }
  }
}

interface InputItem {
  id: string;
  parents: Set<string>;
  view?: string
}
interface TreeNode {
  name: string;
  children: TreeNode[];
  source: InputItem;
  type: 'ROOT'|'TABLE'|'VIEW';
}
function buildTree(items: InputItem[]): TreeNode {

  const root: TreeNode = {
    name: 'model',
    children: [],
    source: { id: 'model', parents: new Set() },
    type: 'ROOT'
  };

  if (!items || items.length === 0)
    return root;

  const nodes: Map<string, TreeNode> = new Map();
  nodes.set('model', root);

  // Create nodes for each item
  items.forEach(item => {
    const node: TreeNode = {
      name: item.id,
      children: [],
      source: item,
      type: item.view ? 'VIEW' : 'TABLE'
    };
    nodes.set(item.id, node);
  });

  // Link nodes to form the tree
  items.forEach(item => {

    const node = nodes.get(item.id);
    if (!node) {
      console.error('Node not found for item', item);
      return;
    }

    const parentIds = Array.from(item.parents);
    const primaryParentId = parentIds.length > 0 ? parentIds[0] : 'model'; // If no parent, attach to model

    const parentNode = nodes.get(primaryParentId);
    if (!parentNode)
    {
      console.error('Parent node not found for item', item);
      return;
    }

    parentNode.children.push(node);
  });

  return root;
}
