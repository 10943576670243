import {css, html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators';
import {Notification, notificationService} from "../services/notificationService";
const log = Debug(`pdq:ui:pdq-notification-stack`);

import '@carbon/web-components/es/components/notification/index.js';
import Debug from "debug";

@customElement('pdq-notification-stack')
export class PdqNotificationStack extends LitElement {
    static override styles = css`
      :host {
        --toast-margin: 10px;
        --toast-width: 800px;
        --toast-animation-duration: 0.5s;
          
        display: flex;
        flex-direction: column-reverse;
        position: fixed;
        right: 0;
        left:0;
        bottom: 0;
        padding: var(--toast-margin, 16px);
        z-index: 100;
      }
      
      .toast {
        display: flex;
        align-items: center;
        padding: 10px;
        animation: slide-up var(--toast-animation-duration, 0.5s) ease-out;
        z-index: 101;
      }
      .toast.slide-out {
        z-index: 99;
        pointer-events: none;
        animation: slide-out var(--toast-animation-duration, 0.5s) ease-out;
      }
      @keyframes slide-up {
        from {
          transform: translateY(100%);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
            
    @keyframes slide-out {
      from {
        transform: translateY(0);
        opacity: 1;
      }
      to {
        transform: translateY(100%);
        opacity: 0;
      }
    }
  `;

    @property({type: Array}) messages:DismissableNotification[] = [];

    constructor() {
        super();
        notificationService.notifications.subscribe( (i: Notification ) => this.notify(i))
    }

    override render(){
        return html`${this.messages.map((message: DismissableNotification) => {
            
            let title = message.type[0].toLocaleUpperCase() + message.type.substring(1)
            let messageText = message.message as string
            if (message.message.indexOf('|') !== -1)
                [title, messageText] = message.message.split('|')
            
            
            return html`
            <cds-inline-notification
                    id="pdq-notification-${message.id}"
                    style="width: 100%; flex-grow: 1"
                    class="toast ${message.type}" 
                    inline="true"
                    title="${title}"
                    kind="${message.type}"
                @cds-notification-beingclosed="${function(evt: Event){
                    setTimeout(()=> message.close(), 499);
                    (evt.currentTarget as HTMLElement)?.classList.add('slide-out')
                }}" 
                @cds-notification-closed="${function(evt: Event){
                    setTimeout(()=> message.close(), 499);
                    (evt.currentTarget as HTMLElement)?.classList.add('slide-out')
                }}">
                ${messageText}</cds-inline-notification>`
        })}
        `
    }

    private close(id: number){
        this.messages = this.messages.filter((m: DismissableNotification) => m.id !== id)
        log(`removed ${id}`, this.messages)
        this.requestUpdate();
    }
    static _nextId = 0;
    private notify(notification: Notification){
        const id = PdqNotificationStack._nextId++;
        this.messages.push({...notification, id, close: () => this.close(id)});
        this.requestUpdate();
    }
}


interface DismissableNotification extends Notification{
    id: number,
    close: () => void
}