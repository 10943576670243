/**
 * @license
 *
 * Copyright IBM Corp. 2019, 2023
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import CheckmarkFilled16 from '@carbon/icons/lib/checkmark--filled/16';
import ErrorFilled16 from '@carbon/icons/lib/error--filled/16';
import { prefix } from '../../globals/settings';
import LOADING_TYPE from '../loading/types';
import getLoadingIcon from '../loading/loading-icon';
import { INLINE_LOADING_STATE } from './defs';
import styles from './inline-loading.scss';
import { carbonElement as customElement } from '../../globals/decorators/carbon-element';

export { INLINE_LOADING_STATE };

/**
 * Lnline loading spinner.
 *
 * @element cds-inline-loading
 */
@customElement(`${prefix}-inline-loading`)
class CDSInlineLoading extends LitElement {
  /**
   * The assistive text for the spinner icon.
   */
  @property({ attribute: 'assistive-text' })
  assistiveText = 'Loading';

  /**
   * @returns The template for the status icon.
   */
  private _renderIcon() {
    const { assistiveText, status } = this;
    if (status === INLINE_LOADING_STATE.ERROR) {
      return ErrorFilled16({
        class: `${prefix}--inline-loading--error`,
      });
    }
    if (status === INLINE_LOADING_STATE.FINISHED) {
      return CheckmarkFilled16({
        class: `${prefix}--inline-loading__checkmark-container`,
      });
    }
    if (
      status === INLINE_LOADING_STATE.INACTIVE ||
      status === INLINE_LOADING_STATE.ACTIVE
    ) {
      const classes = classMap({
        [`${prefix}--loading`]: true,
        [`${prefix}--loading--small`]: true,
        [`${prefix}--loading--stop`]: status === INLINE_LOADING_STATE.INACTIVE,
      });
      return html`
        <div class="${classes}">
          ${getLoadingIcon({ assistiveText, type: LOADING_TYPE.SMALL })}
        </div>
      `;
    }
    return undefined;
  }

  /**
   * The loading status.
   */
  @property({ reflect: true })
  status = INLINE_LOADING_STATE.ACTIVE;

  connectedCallback() {
    if (!this.hasAttribute('aria-live')) {
      this.setAttribute('aria-live', 'assertive');
    }
    super.connectedCallback();
  }

  render() {
    const statusIconResult = this._renderIcon();
    const statusIconWrapperResult = !statusIconResult
      ? undefined
      : html`
          <div class="${prefix}--inline-loading__animation">
            ${statusIconResult}
          </div>
        `;
    return html`
      ${statusIconWrapperResult}
      <p class="${prefix}--inline-loading__text"><slot></slot></p>
    `;
  }

  static styles = styles; // `styles` here is a `CSSResult` generated by custom WebPack loader
}

export default CDSInlineLoading;
