import { customElement, property } from 'lit/decorators';
import { css, html, LitElement, nothing } from 'lit';
import '@carbon/web-components/es/components/modal';
import { PropertyValues } from '@lit/reactive-element';
import { googleApi } from './googleApi';
import Debug from 'debug';
import { tableBroker } from '../services/TableBroker';
import { blobCache } from '../services/BlobCache';
import { dataBroker } from '../services/DataBroker';

const log = Debug('pdq:google:PdqGoogleFileRequest');

@customElement('pdq-google-file-request')
export class PdqGoogleFileRequest extends LitElement {
  @property({ type: String })
  fileId: string = '';

  @property({ type: String })
  alt: string = 'media'

  @property({ type: String })
  index: string|undefined = undefined

  @property({ type: Number })
  expires = 1000 * 60 * 60 * 24 * 20; // 90 day

  static styles = css``;

  render() {
    return nothing;
  }

  updated(changedProperties: PropertyValues) {
    log('updated');
    this.updateFileAsRequired();
  }

  firstUpdated() {
    log('firstUpdated');
    this.updateFileAsRequired();
  }

  private lastCacheKey = ''
  private async updateFileAsRequired() {
    if (!this.fileId)
      return;

    const cacheKey =  this.fileId
    if (this.lastCacheKey === cacheKey)
      return
    this.lastCacheKey = cacheKey
    log(`loading file from google ${this.fileId}`)
    const meta = await blobCache.get(`google-file-${this.fileId}`,
      () => googleApi.getFileContent(this.fileId, this.alt) as Promise<Blob>,
      this.expires);

    const file = meta.blob;
    await dataBroker.addBlobToData(this.fileId, file);

    // log(`updating datasource[${this.table}] with file ${this.fileId}`);
    // try {
    //   const json = await blobToJson(file!);
    //   log(json)
    //   await perspectiveBroker.updateDataSource(this.table, [json], this.index);
    // } catch (ex) {
    //   log(`error updating datasource[${this.table}] with file ${this.fileId} ${ex}`)
    // }

  }
}
