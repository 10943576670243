import { customElement, property, state } from 'lit/decorators';
import { css, html, LitElement, PropertyValues } from 'lit';
import { center } from '../styles';
import { googleAuth } from './googleAuth';
import { googleApi } from './googleApi';
import { blobCache } from '../services/BlobCache';
import Debug from 'debug';
import { INLINE_LOADING_STATE } from '@carbon/web-components/es/components/inline-loading/defs';
const log = Debug('pdq:google:pdq-google-file-viewer');

@customElement('pdq-google-file-viewer')
export class PdqGoogleFileViewer extends LitElement {
  protected _fileId: any;
  @property()
  loadingMessage = 'Loading...';

  @property({type: String})
  get fileId () {
    return this._fileId
  }
  set fileId (value) {
    this._fileId = value
    this.loadAndRenderPDF()
  }

  @state()
  loading = true

  @state()
  loginRequired = false


  @state() url: string = '';

  static styles = [
    center,
    css`
    .pdf-page {
      margin-bottom: 10px;
    }
  `];

  firstUpdated() {
    log('firstUpdated');
    this.loadAndRenderPDF();
  }

  updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    log('update');
    if (changedProperties.has('fileId')) {
      this.loadAndRenderPDF();
    }
  }

  #cacheKey = '-'
  async loadAndRenderPDF() {
    if (this.#cacheKey === this.fileId)
      return
    this.#cacheKey = this.fileId
    if (!this.fileId) {
      log(`cant load empty fileId`)
      this.url = ''
      return
    }

    try {
      log(`loading file ${this.fileId}`)
      this.loading = true
      this.loginRequired = !googleAuth.loggedIn.value
      this.requestUpdate()
      this.url = (await blobCache.get(`google-file-${this.fileId}`,
        () => googleApi.getFileContent(this.fileId) as Promise<Blob>)).url
      log(`loading file ${this.fileId} done ${this.url}`)
    } catch (error) {
      console.error('Error rendering PDF:', error);
    } finally {
      this.loading = false
    }
  }


  render() {
    log(`rendering ${this.url} ${this.loading} ${this.loginRequired}`)

    if (!this.loading && !this.url)
      return html`<div class="center"><cds-inline-loading style="margin-left: 16px" status="error">No url set</cds-inline-loading></div>`

    if (this.loading && this.loginRequired)
      return html`<div class="center"><cds-inline-loading style="margin-left: 16px" status="active">${this.loadingMessage} Awaiting Login</cds-inline-loading></div>`

    if (this.loading)
      return html`<div class="center"><cds-inline-loading style="margin-left: 16px" status="active">${this.loadingMessage}</cds-inline-loading></div>`

    return html`<iframe
      src=${this.url}
      style="width: 100%; height: 100%; display: block; border: 0">
      ></iframe>`;
  }
}