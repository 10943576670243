// components barrel
import { notificationService } from './services/notificationService';

export * from './ui/pdq-notification-stack'
export * from './ui/pdq-confirm-dialog'
export * from "./ui/pdq-confirm-dialog";

// export * from './ui/pdq-s3-file-metadata'
// export * from './ui/pdq-s3-upload'
export * from './ui/pdq-pdf-viewer'
export * from './ui/pdq-icon'
export * from './ui/pdq-theme'


// flow
export * from './flow/pdq-json'
export * from './flow/pdq-perspective-viewer'
export * from "./flow/pdq-view";
export * from "./flow/pdq-dump";
export * from "./flow/pdq-data-request";
export * from "./flow/pdq-toolbar";
export * from "./flow/pdq-data-model";
export * from "./flow/pdq-transform";
export * from "./flow/pdq-table";
// liquid
export * from './liquid/pdq-liquid';
//export {PdqLiquidVariable} from "./liquid/pdq-liquid-variable";
//export {PdqLiquidVariableStack} from "./liquid/pdq-liquid-variable-stack";

// forms
export * from './forms/pdq-schema-form'

// services barrel
export * from './services/notificationService'
// export * from './services/PdqS3Service'
export * from './services/TableBroker'
export * from './services/DataClient'


// google
import './google/googleAuth';
import './google/googleApi';
export * from './google/pdq-google-auth';
export * from './google/pdq-google-login-button';
export * from './google/pdq-google-api';
export * from './google/pdq-google-file-request';
export * from './google/pdq-google-file-viewer';




// carbon
// Accordion components
import '@carbon/web-components/es/components/accordion/index.js';
// import '@carbon/web-components/es/components/accordion/accordion.js';
// import '@carbon/web-components/es/components/accordion/accordion-item.js';


// Button components
import '@carbon/web-components/es/components/button/index.js';
//import '@carbon/web-components/es/components/button/button.js';

// Checkbox components
import '@carbon/web-components/es/components/checkbox/index.js';
//import '@carbon/web-components/es/components/checkbox/checkbox.js';

// CodeSnippet components
import '@carbon/web-components/es/components/code-snippet/index.js';
//import '@carbon/web-components/es/components/code-snippet/code-snippet.js';

// Combobox components
import '@carbon/web-components/es/components/combo-box/index.js';
// import '@carbon/web-components/es/components/combo-box/combo-box.js';
// import '@carbon/web-components/es/components/combo-box/combo-box-item.js';

// Data table components
import '@carbon/web-components/es/components/data-table/index.js';
//import '@carbon/web-components/es/components/data-table/table.js';
//import '@carbon/web-components/es/components/data-table/table-header-cell.js';
//import '@carbon/web-components/es/components/data-table/table-header-row.js';
//import '@carbon/web-components/es/components/data-table/table-row.js';
//import '@carbon/web-components/es/components/data-table/table-cell.js';
//import '@carbon/web-components/es/components/data-table/table-toolbar.js';
//import '@carbon/web-components/es/components/data-table/table-toolbar-search.js';
//import '@carbon/web-components/es/components/data-table/table-toolbar-content.js';
//import '@carbon/web-components/es/components/data-table/table-toolbar-menu.js';
//import '@carbon/web-components/es/components/data-table/table-toolbar-action.js';
//import '@carbon/web-components/es/components/data-table/table-batch-actions.js';
//import '@carbon/web-components/es/components/data-table/table-batch-action.js';

// Date Picker components
import '@carbon/web-components/es/components/date-picker/index.js';
// import '@carbon/web-components/es/components/date-picker/date-picker.js';
// import '@carbon/web-components/es/components/date-picker/date-picker-input.js';
//import '@carbon/web-components/es/components/date-picker/date-picker-dropdown.js';

// Dropdown components
import '@carbon/web-components/es/components/dropdown/index.js';
// import '@carbon/web-components/es/components/dropdown/dropdown.js';
// import '@carbon/web-components/es/components/dropdown/dropdown-item.js';
//import '@carbon/web-components/es/components/dropdown/dropdown-item-group.js';

// File Uploader components
import '@carbon/web-components/es/components/file-uploader/index.js';
// import '@carbon/web-components/es/components/file-uploader/file-uploader.js';
// import '@carbon/web-components/es/components/file-uploader/file-uploader-item.js';
// import '@carbon/web-components/es/components/file-uploader/file-uploader-drop-container.js';

// Form components
import '@carbon/web-components/es/components/form/index.js';
//import '@carbon/web-components/es/components/form/form-item.js';

// Icon components
// import '@carbon/web-components/es/components/icon/icon.js';

// Inline Loading components
import '@carbon/web-components/es/components/inline-loading/index.js';
//import '@carbon/web-components/es/components/inline-loading/inline-loading.js';

// Input components
import '@carbon/web-components/es/components/text-input/index.js';
//import '@carbon/web-components/es/components/input/input.js';

// Link components
import '@carbon/web-components/es/components/link/index.js';
//import '@carbon/web-components/es/components/link/link.js';

// List components
import '@carbon/web-components/es/components/list/index.js';
//import '@carbon/web-components/es/components/list/list-item.js';

// Loading components
import '@carbon/web-components/es/components/loading/index.js';
//import '@carbon/web-components/es/components/loading/loading.js';

// Modal components
import '@carbon/web-components/es/components/modal/index.js';
//import '@carbon/web-components/es/components/modal/modal.js';

// Multi Select components
import '@carbon/web-components/es/components/multi-select/index.js';
// import '@carbon/web-components/es/components/multi-select/multi-select.js';
// import '@carbon/web-components/es/components/multi-select/multi-select-item.js';

// Notification components
import '@carbon/web-components/es/components/notification/index.js';
// import '@carbon/web-components/es/components/notification/inline-notification.js';
// import '@carbon/web-components/es/components/notification/toast-notification.js';

// Number Input components
import '@carbon/web-components/es/components/number-input/index.js';
//import '@carbon/web-components/es/components/number-input/number-input.js';

// Overflow Menu components
import '@carbon/web-components/es/components/overflow-menu/index.js';
// import '@carbon/web-components/es/components/overflow-menu/overflow-menu.js';
// import '@carbon/web-components/es/components/overflow-menu/overflow-menu-item.js';

// Pagination components
import '@carbon/web-components/es/components/pagination/index.js';
//import '@carbon/web-components/es/components/pagination/pagination.js';

// Progress Indicator components
import '@carbon/web-components/es/components/progress-indicator/index.js';
// import '@carbon/web-components/es/components/progress-indicator/progress-indicator.js';
// import '@carbon/web-components/es/components/progress-indicator/progress-step.js';

// Radio Button components
import '@carbon/web-components/es/components/radio-button/index.js';
// import '@carbon/web-components/es/components/radio-button/radio-button.js';
// import '@carbon/web-components/es/components/radio-button/radio-button-group.js';

// Search components
import '@carbon/web-components/es/components/search/index.js';
//import '@carbon/web-components/es/components/search/search.js';

// Select components
import '@carbon/web-components/es/components/select/index.js';
// import '@carbon/web-components/es/components/select/select.js';
// import '@carbon/web-components/es/components/select/select-item.js';
// import '@carbon/web-components/es/components/select/select-item-group.js';

// Slider components
import '@carbon/web-components/es/components/slider/index.js';
// import '@carbon/web-components/es/components/slider/slider.js';
// import '@carbon/web-components/es/components/slider/slider-input.js';

// Structured List components
import '@carbon/web-components/es/components/structured-list/index.js';
// import '@carbon/web-components/es/components/structured-list/structured-list.js';
// import '@carbon/web-components/es/components/structured-list/structured-list-row.js';
// import '@carbon/web-components/es/components/structured-list/structured-list-header-row.js';
// import '@carbon/web-components/es/components/structured-list/structured-list-cell.js';

// Tabs components
import '@carbon/web-components/es/components/tabs/index.js';
// import '@carbon/web-components/es/components/tabs/tabs.js';
// import '@carbon/web-components/es/components/tabs/tab.js';
//import '@carbon/web-components/es/components/tabs/tab-panel.js';

// Tag components
import '@carbon/web-components/es/components/tag/index.js';
//import '@carbon/web-components/es/components/tag/tag.js';

// Text Area components
import '@carbon/web-components/es/components/textarea/index.js';

// Text Input components
import '@carbon/web-components/es/components/text-input/index.js';
//import '@carbon/web-components/es/components/text-input/text-input.js';

// Tile components
import '@carbon/web-components/es/components/tile/index.js';
// import '@carbon/web-components/es/components/tile/tile.js';
// import '@carbon/web-components/es/components/tile/selectable-tile.js';
// import '@carbon/web-components/es/components/tile/expandable-tile.js';
// import '@carbon/web-components/es/components/tile/clickable-tile.js';
// import '@carbon/web-components/es/components/tile/radio-tile.js';

// Toggle components
import '@carbon/web-components/es/components/toggle/index.js';
//import '@carbon/web-components/es/components/toggle/toggle.js';

// Tooltip components
import '@carbon/web-components/es/components/tooltip/index.js';
// import '@carbon/web-components/es/components/tooltip/tooltip.js';
// import '@carbon/web-components/es/components/tooltip/tooltip-definition.js';
// import '@carbon/web-components/es/components/tooltip/tooltip-icon.js';

import '@carbon/web-components/es/components/ui-shell/index.js';
import '@carbon/web-components/es/components/floating-menu/index.js';


import '@carbon/layout/es/index.js'
import { blobRegistry } from './services/BlobRegistry';
import { blobCache } from './services/BlobCache';
import { tableBroker } from './services/TableBroker';
import { dataClient } from './services/DataClient';
import { dataBroker } from './services/DataBroker';
import { googleAuth } from './google/googleAuth';
import { googleApi } from './google/googleApi';


// Re-export all components - parcel not re-exporting with current config
// export * from '@carbon/web-components';

import {Subject, ReplaySubject, BehaviorSubject, combineLatest, from, of, firstValueFrom} from 'rxjs';


if (window)
(window as any).pdq = {
    notificationService: notificationService,
    blobRegistry: blobRegistry,
    blobCache: blobCache,
    tableBroker: tableBroker,
    dataClient: dataClient,
    dataBroker: dataBroker,
    googleAuth: googleAuth,
    googleApi: googleApi,

    Subject, ReplaySubject, BehaviorSubject, combineLatest, from, of, firstValueFrom
}


