import Debug from 'debug';
import { indexOf } from '@carbon/web-components/es/globals/internal/collection-helpers';

const log = Debug('pdq:observable');

export const DONT_TRIGGER_UPDATE = Symbol("DONT_TRIGGER_UPATE")

class LimitedLengthQueue<T> {
    private queue: T[] = [];
    readonly limit: number;

    constructor(limit: number) {
        if (limit < 1) {
            throw new Error("Limit must be at least 1");
        }
        this.limit = limit;
    }

    push(item: T): void {
        this.queue.push(item);
        while (this.queue.length > this.limit) {
            this.queue.shift();
        }
    }

    read(): T[] {
        return [...this.queue];
    }
}

export class ReplaySubject<T> implements IObservable<T> {
    readonly #observers: Array<(arg:T) => any>;
    #previousValues: LimitedLengthQueue<T>;
    constructor(replays: number){
        this.#observers = [];
        this.#previousValues = new LimitedLengthQueue<T>(replays);
    }
    next(value: T){
        if (value == DONT_TRIGGER_UPDATE)
            return;
        this.#previousValues.push(value);
        for(const o of this.#observers){
            o(value)
        }
    }
    subscribe(observer: (arg:T) => any){
        this.#observers.push(observer)
        for (const pv of this.#previousValues.read()) {
            observer(pv);
        }
        return () => {
            const index = this.#observers.indexOf(observer);
            if (index !== -1) {
                this.#observers.splice(index, 1);
            }
        }
    }
    currentValue(): T|undefined {
        return this.#previousValues.read().pop();
    }
    // toString(){
    //     return `ReplaySubject<${typeof this.currentValue()}>(${this.#previousValues.limit}): ${this.currentValue()}`
    // }
}

class Observer<T> {
    protected _observers = [] as  Array<(args: T) => void>;
    protected _publishedValue = undefined as T|undefined;
    subscribe: (callback: (arg: T) => void) => () => void;
    constructor(subscribe: (callback: (arg: T) => void) => () => void    ){
        this.subscribe = subscribe;
    }
    currentValue = () => this._publishedValue;
}

export function combine<T extends any[]>(...sources: Array<any>): IObservable<T> {

    function subscribe(this: Observer<T>, observer: (args: T) => void) {
        this._observers.push(observer);

        const unsubscribe =  () => {
            const index = this._observers.indexOf(observer);
            if (index !== -1) {
                this._observers.splice(index, 1);
            }
            if (this._observers.length === 0)
            {
                subscriptions.forEach(unsubscribe => unsubscribe());
            }
        };

        if (this._observers.length > 1)
            return unsubscribe;

        const values: (T | undefined)[] = new Array(sources.length).fill(undefined);
        const hasValue: boolean[] = new Array(sources.length).fill(false);
        let hasValuesFromAllSources = false;

        const checkCompletion = () => !hasValue.includes(false);

        const subscriptions = sources.map((source, index) => source.subscribe((value:any) => {
            log('combine:subscriptions', source, index, value)
            if (value === DONT_TRIGGER_UPDATE)
                return
            values[index] = value;
            if (!hasValue[index]) {
                hasValue[index] = true;
                hasValuesFromAllSources = checkCompletion();
            }

            if (hasValuesFromAllSources) {
                this._publishedValue = values as T;
                for (const o of this._observers) {
                    o([...values] as T)
                }
            }
        }));

        return unsubscribe;
    }

    return new Observer (subscribe)
}
export function distinct<T>(source: IObservable<T>): IObservable<T> {
    const initialValueSymbol = Symbol("InitialValue");
    let lastValue: T | typeof initialValueSymbol = initialValueSymbol;
    let _unsub : () => void;
    function subscribe(this: Observer<T>, observer: (arg: T) => any): () => void {
        this._observers.push(observer);

        const unsubscribe =  () => {
            const index = this._observers.indexOf(observer);
            if (index !== -1) {
                this._observers.splice(index, 1);
            }
            if (this._observers.length === 0)
            {
                _unsub();
            }
        };

        if (this._observers.length > 1)
            return unsubscribe;

        _unsub = source.subscribe(value => {
            if (value !== lastValue && value !== DONT_TRIGGER_UPDATE) {
                lastValue = value;
                for (const o of this._observers) {
                    this._publishedValue = value;
                    o(value)
                }
            }
        });

        return unsubscribe
    }

    return new Observer (subscribe);
}
export function map<T, T2>(source: IObservable<T>, mapper: (v:T)=>T2, awaitPromises: boolean = true): IObservable<T2> {
    const initialValueSymbol = Symbol("InitialValue");
    let lastValue: T | typeof initialValueSymbol = initialValueSymbol;
    let _unsub : () => void;
    const supersededPromises = new Map<Promise<any>, boolean>()
    function subscribe(this: Observer<T2>, observer: (arg: T2) => any): () => void {
        this._observers.push(observer);

        async function unwrap(promiseOrValue: Promise<any>|any): Promise<any|typeof DONT_TRIGGER_UPDATE> {
            while (promiseOrValue instanceof Promise) {
                supersededPromises.set(promiseOrValue, false);
                const unwrapped = await promiseOrValue;
                const superseded = supersededPromises.get(promiseOrValue)
                supersededPromises.delete(promiseOrValue)
                if (superseded)
                    return DONT_TRIGGER_UPDATE;
                promiseOrValue = unwrapped;
            }
            return promiseOrValue;
        }

        const unsubscribe =  () => {
            const index = this._observers.indexOf(observer);
            if (index !== -1) {
                this._observers.splice(index, 1);
            }
            if (this._observers.length === 0)
            {
                _unsub();
            }
        };

        if (this._observers.length > 1)
            return unsubscribe;

        _unsub = source.subscribe(async value => {
            for (const key of supersededPromises.keys())
                supersededPromises.set(key, true);

            if (awaitPromises) {
                value = await unwrap(value);
            }
            if (value === DONT_TRIGGER_UPDATE) {
                log("DONT_TRIGGER_UPDATE - stage 1")
                return;
            }

            let mapped = mapper(value);
            if (awaitPromises) {
                mapped = await unwrap(mapped);
            }
            if (mapped === DONT_TRIGGER_UPDATE) {
                log("DONT_TRIGGER_UPDATE - stage 2")
                return;
            }

            for (const o of this._observers) {
                this._publishedValue = mapped;
                o(mapped)
            }
        });
        return unsubscribe
    }
    return new Observer (subscribe);
}

export function one<T>(source: IObservable<T>): Promise<T>{
    let unsub: undefined|(() => void);
    return new Promise((resolve, reject) => {
        unsub = source.subscribe(i => {
            resolve(i)
            unsub!();
        })
    });
}
export function first<T>(source: IObservable<T>, predicate: (x:T) => boolean = (x => !!x)): Promise<T>{
    let unsub: undefined|(() => void);
    return new Promise((resolve, reject) => {
        unsub = source.subscribe(i => {
            if (!predicate(i))
                return
            resolve(i)
            unsub!();
        })
    });
}
export function staticObservable<T>(value: T){
    const rs = new ReplaySubject(1);
    rs.next(value)
    return rs;

}


map.DONT_TRIGGER_UPATE = DONT_TRIGGER_UPDATE
export interface IObservable<T> {
    subscribe(observer: (arg:T) => any): () => void;
    currentValue(): T|undefined
}