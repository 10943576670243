import {css} from "lit";

export const hide = css`
  .hide:not(show) {
    display: none;
  }
`
export const row = css`
  .row {
    display: flex;
    flex-direction: row;
  }
`
export const col = css`
  .col {
    display: flex;
    flex-direction: column;
  }
`
export const center = css`
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const grow = css`
    .grow {
        flex-grow: 1;
    }
`
export const shrink = css`
    .shrink {
        flex-shrink: 1;
    }
`
export const wrap = css`
    .wrap {
        flex-wrap: wrap;
    }
`

const sizes = [0,8,16,24,32,48,64,80,96];
export const margin = sizes.map((size,idx) => css`.m-${idx} { margin: ${size}px; }`)
export const marginLeft = sizes.map((size,idx) => css`.ml-${idx} { margin-left: ${size}px; }`)
export const marginTop = sizes.map((size,idx) => css`.mt-${idx} { margin-top: ${size}px; }`)
export const marginRight = sizes.map((size,idx) => css`.mr-${idx} { margin-right: ${size}px; }`)
export const marginBottom = sizes.map((size,idx) => css`.mb-${idx} { margin-bottom: ${size}px; }`)

export const marginAll = [...margin, ...marginLeft, ...marginTop, ...marginRight, ...marginBottom ]

export const padding = sizes.map((size,idx) => css`.p-${idx} { padding: ${size}px; }`)
export const paddingLeft = sizes.map((size,idx) => css`.p-${idx} { padding-left: ${size}px; }`)
export const paddingTop = sizes.map((size,idx) => css`.p-${idx} { padding-top: ${size}px; }`)
export const paddingRight = sizes.map((size,idx) => css`.p-${idx} { padding-right: ${size}px; }`)
export const paddingBottom = sizes.map((size,idx) => css`.p-${idx} { padding-bottom: ${size}px; }`)
export const paddingAll = [...padding, ...paddingLeft, ...paddingTop, ...paddingRight, ...paddingBottom ]