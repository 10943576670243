import { getSha256, Mutex } from '../utils';

export class BlogRegistry {
  blobsByHash = new Map<string, BlobMeta>();
  blobsByUrl = new Map<string, BlobMeta>();
  mutex = new Mutex('BlobRegistry');

  async getBlobByHash(hash: string): Promise<BlobMeta | undefined> {
    return this.blobsByHash.get(hash);
  }
  async getBlobByUri(hash: string): Promise<BlobMeta | undefined> {
    return this.blobsByUrl.get(hash);
  }
  async unregisterBlobByHash(hash: string): Promise<void> {
    this.blobsByUrl.get(hash)?.unregister();
  }
  async unregisterBlobByUrl(url: string): Promise<void> {
    this.blobsByUrl.get(url)?.unregister();
  }
  async registerBlob(blob: Blob): Promise<BlobMetaCreated> {
    const hash = await getSha256(blob);
    const unlock = await this.mutex.lock()
    try {
      if (this.blobsByHash.has(hash))
        return { ...this.blobsByHash.get(hash)!, added: false };

      const url = URL.createObjectURL(blob);
      const meta = {
        url,
        hash,
        blob,
        unregister: () => {
          URL.revokeObjectURL(url);
          this.blobsByHash.delete(hash);
          this.blobsByUrl.delete(url);
        }
      } as BlobMeta;
      this.blobsByHash.set(hash, meta);
      this.blobsByUrl.set(url, meta);
      return { ...meta, added: true };
    } finally {
      unlock()
    }
  }
  async registerStringAsBlob( msg: string, mimeType: string): Promise<BlobMetaCreated> {
    const blob = new Blob([msg], {type: mimeType});
    return this.registerBlob(blob);
  }
}

export interface BlobMeta {
  url: string
  hash: string
  blob: Blob
  unregister: () => {}
}

export interface BlobMetaCreated extends BlobMeta{
  added: boolean
}

export const blobRegistry = new BlogRegistry();
