import {ReplaySubject} from "../observable/ReplaySubject";
import Debug from "debug";
import {notificationService} from "./notificationService";

const log = Debug('pdq:services:PdqDataClient');


export const CacheStrategies = {
    useStale:'useStale',
    alwaysCheck: 'alwaysCheck',
    neverCache: 'neverCache'
} as const;
export type CacheStrategy = typeof CacheStrategies[keyof typeof CacheStrategies];

export const DataProcessors =  {
    json: 'json',
    blob: 'blob',
    rawResponse: 'rawResponse'
} as const
export type DataProcessor = typeof DataProcessors[keyof typeof DataProcessors];

interface DataRequestMeta {
    url: string
    fetchOptions: any
    response: DataResponseMeta
    strategy: CacheStrategy
    processor: (dr: DataRequestMeta, r:Response) => void
}
export class DataResponseMeta {
    data: ReplaySubject<any> = new ReplaySubject<any>(1)
    loading: ReplaySubject<boolean> = new ReplaySubject<any>(1)
    error: ReplaySubject<string|undefined> = new ReplaySubject<any>(1)
}

export class DataClient {
    private _observedRequests = new Map<string, DataRequestMeta>();
    private _cache = caches.open('pdq');
    async get(url: string, strategy: CacheStrategy = CacheStrategies.useStale, processor:DataProcessor = DataProcessors.json, fetchOptions: any = {}): Promise<DataResponseMeta> {
        log(`Getting ${url} with strategy: ${strategy} and processor: ${processor}`)
        const dr = this._observedRequests.get(url)! || this._newDataRequest(url, strategy, processor, fetchOptions);
        dr.response.loading.next(true);
        let response = null

        switch (dr.strategy) {
            case CacheStrategies.alwaysCheck:
                response = await this._updateCacheIfNeeded(dr);
                break;
            case CacheStrategies.neverCache:
                response = await this._fetch(dr);
                break
            case CacheStrategies.useStale:
                response = await this._getCachedResponse(dr);
                if (response) {
                    this._updateCacheIfNeeded(dr).then(i => dr.processor(dr, i));
                }else {
                    response = await this._cacheFile(dr);
                }
                break
            default: throw new Error(`${dr.strategy} not found`);
        }

        dr.processor(dr, response);
        return dr.response;
    }

    private async _fetch(dr: DataRequestMeta){
        log('starting fetch', dr);
        const response = await fetch(dr.url, dr.fetchOptions);
        log('fetch complete', dr);
        return response;
    }
    private async _cacheFile(dr: DataRequestMeta) {
        const cache = await this._cache
        const response = await this._fetch(dr);
        if (response.status === 200) {
            await cache.put(dr.url, response.clone());
        }
        return response;
    }
    private async _getCachedResponse(dr: DataRequestMeta){
        const cache = await this._cache
        return await cache.match(dr.url);
    }
    private async _updateCacheIfNeeded(dr: DataRequestMeta): Promise<Response> {
        const cache = await this._cache;
        const cachedResponse = await this._getCachedResponse(dr);
        if (cachedResponse) {
            const etag = cachedResponse.headers.get('ETag');
            dr.fetchOptions = {
                ...(dr.fetchOptions || {}),
                headers: {
                    ...(dr.fetchOptions?.headers || {}),
                    'If-None-Match': etag
                }
            };
            const response = await this._fetch(dr)
            if (response.status === 304) {

                log('Cached version is up to date.');
                return cachedResponse;
            }
            // not all servers support If-None-Match / return 304, so we need to check the etag before pulling body
            const newEtag = response.headers.get('ETag');
            if (!etag || etag !== newEtag) {
                log(`Updating cache for: ${dr.url} old eTag=${etag} newETag=${newEtag}`);
                await cache.put(dr.url, response.clone());
                return response;
            }

            log('Cached version is up to date.');
            return cachedResponse;

        } else {
            log('No cached version found, caching now.');
            return await this._cacheFile(dr);
        }
    }

    private _newDataRequest(url: string, strategy: CacheStrategy, processorType: DataProcessor, fetchOptions: any ): DataRequestMeta {
        let processor = null;

        const response = new DataResponseMeta()
        const updateSuccess = function (m: DataRequestMeta, data: any){
            m.response.data.next(data);
            m.response.loading.next(false);
            m.response.error.next(undefined);
        }
        const updateError = function (m: DataRequestMeta, error: any){
            m.response.data.next(undefined);
            m.response.loading.next(false);
            m.response.error.next(error);
        }

        switch (processorType) {
            case DataProcessors.json:
                processor = async (dr: DataRequestMeta, r: Response) => {
                    if (r.status >= 200 && r.status < 300)
                        return r.json().then(i => updateSuccess(dr, i), e => updateError(dr, e))
                    try {
                        const body = await r.text()
                        updateError(dr, `Request Failed. Server returned: ${r.status}: ${r.statusText}\n${body}`);
                    } catch (e) {
                        updateError(dr, `Request Failed. Server returned: ${r.status}: ${r.statusText}`);
                    }
                }
                break;
            case DataProcessors.blob:
                processor = async (dr: DataRequestMeta, r: Response) => r.blob()
                    .then(
                        i => {
                            response.data.next(i);
                            response.loading.next(false);
                            response.error.next(undefined);
                        },
                        e => {
                            notificationService.warning(`Request: ${dr.url} failed to parse. Error: ${e}`);
                            response.data.next(undefined);
                            response.loading.next(false);
                            response.error.next(`Request: ${dr.url} failed to parse. Error: ${e}`);
                        }
                    )
                break;
            case DataProcessors.rawResponse:
                processor = async (dr: DataRequestMeta, r: Response) =>
                {
                    response.data.next(r);
                    response.loading.next(false);
                    response.error.next(undefined);
                };
                break;
            default:
                throw new Error(`Unknown Processor "${processorType}"`);
        }

        const dr:DataRequestMeta = {
            url,
            strategy,
            fetchOptions,
            processor,
            response
        }
        this._observedRequests.set(url, dr);
        return dr;
    }
}

export const dataClient = new DataClient();