import { html, LitElement, nothing } from 'lit';
import { customElement, property, query, state } from 'lit/decorators';
import { col, grow, hide, margin, padding, row } from '../styles';
import { extractTextFromScripts, safeFireAndForgetFactory } from '../utils';
import { notificationService } from '../services/notificationService';
import Debug from 'debug';
import '@carbon/web-components/es/components/copy-button/index.js';
import '@carbon/web-components/es/components/icon-button/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import '@carbon/web-components/es/components/tag/index.js';
import '@carbon/web-components/es/components/form-group/index.js';
import '@carbon/web-components/es/components/select/index.js';
import '@carbon/web-components/es/components/select/select-item.js';
import '@carbon/web-components/es/components/text-input/index.js';
import Icon from '@carbon/web-components/es/icons/data-table/16.js';
import Save from '@carbon/web-components/es/icons/save/16.js';
import pdqHotkeyService from '../services/PdqHotkeyService';
import { pdqDiscoveryLayer } from '../flow/_pdq-mixin';
import { TAG_TYPE } from '@carbon/web-components/es/components/tag/defs';
import { LiquidVariable } from './liquid-variable';
import { Liquid, Template } from 'liquidjs';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import './pdq-liquid-variable';
import './pdq-liquid-variable-stack';
import { blobRegistry } from '../services/BlobRegistry';
import { dataBroker } from '../services/DataBroker';

import {
  Subject,
  BehaviorSubject,
  combineLatest,
  map,
  of,
  switchMap,
  from,
  Observable,
  mergeAll,
  Subscription,
  tap,
  mergeMap,
  scan,
  startWith,
  shareReplay,
  forkJoin,
  catchError,
  distinctUntilChanged,
  throttleTime,
  debounceTime, ReplaySubject
} from 'rxjs';
import { tableBroker } from '../services/TableBroker';
import { View } from '@finos/perspective';


const liquid = new Liquid();
const log = Debug('pdq:liquid:pdq-liquid');
export const STREAM_START = Symbol('STREAM_START');

@customElement('pdq-liquid')
@pdqDiscoveryLayer(log, Icon, 'pdq-liquid', 'rgba(236, 255, 83, 0.13)', TAG_TYPE.CYAN)
export class PdqLiquid extends LitElement {
  static styles = [hide, row, col, grow, padding, margin];
  private readonly _template$ = new BehaviorSubject<string>('Loading...');
  private readonly _variables$ = new BehaviorSubject<LiquidVariable[]>([]);
  private readonly _data$ = new BehaviorSubject<any>([]);

  @property() error: string | null = null;
  @property() show: boolean = false;
  private _subscription = Subscription.EMPTY;
  private _script: string = '';

  @property({ type: Array, reflect: true })
  get template() { return this._template$.value; }
  set template(value: string) { this._template$.next(value);}

  @property({ type: Array, reflect: true })
  get variables() { return this._variables$.value; }
  set variables(value: LiquidVariable[]) { this._variables$.next(value);}

  @state() dataPreview = 'no data';
  @state() rendered = 'loading...';
  @query(`cds-textarea.template`) templateTextArea: HTMLTextAreaElement | undefined;

  constructor() {
    super();

    const mappedVars$ = this._variables$.pipe(
      map(i => this._resolveVariables(i)),
      mergeMap(resolvedVars => combineLatest(resolvedVars).pipe(
        catchError((e, c) => {
          log(`PIPELINE ERROR: ${e}`, e, c);
          return of([]);
        }),
      )),
      catchError((e,c) => {
        log(`PIPELINE ERROR: ${e} ${c}`, e, c);
        return of([]);
      })
    );


    const passedTemplate$ = this._template$.pipe(
      map(i => liquid.parse(i)),
      tap(() => log('passedTemplate$: updated')),
      shareReplay(1)
    );

    this._subscription = combineLatest([passedTemplate$, mappedVars$]).pipe(
      tap((i) => log('render liquid triggered', i[0], i[1])),
      distinctUntilChanged(),
      // distinctUntilChanged((curr, prev) => {
      //   if (curr[0] != prev[0])
      //     return false;
      //   if (curr[1].length != prev[1].length)
      //     return false;
      //   for (const s in curr[1])
      //     if (curr[1][s] != prev[1][s])
      //       return false;
      //   return true
      // }),
      tap(() => log('render liquid throttling')),
      debounceTime(30),
      tap(() => log('render liquid proceeding')),
    ).subscribe(([tmpl, vars]) => {
      safeFireAndForgetFactory(log)(this._renderLiquid([tmpl, vars]), 'liquid render complete');
    });

   }

   _oldViews = new Map<string, View>();
   _tableSubjects = new Map<string, Subject<any>>();
   _oldDataSubs = new Map<string, Subscription>();
   _dataSubjects = new Map<string, Subject<any>>();
   private _resolveVariables(variable: LiquidVariable[]){
     if (variable.length === 0)
       return of([])
     return variable.map(i => this._resolveVariable(i))
   }
  private _resolveVariable(variable: LiquidVariable): Observable<LiquidVariable> {
    log(`Resolving variable: ${variable.name}`)
    if (variable.source) {
      let subject = this._tableSubjects.get(variable.source!);
      if (!subject) {
        subject = new ReplaySubject(1);
        subject.next(STREAM_START)
        this._tableSubjects.set(variable.source!, subject);
        this._oldDataSubs.set(variable.source!, Subscription.EMPTY)
      }
      dataBroker.getObservableDataSource(variable.source).then(data => {
        this._oldDataSubs.get(variable.source!)?.unsubscribe();
        this._oldDataSubs.set(variable.source!, data.subscribe((data) => {
          subject!.next({
            ...variable,
            value: data
          });
        }));
      })
      return subject;
    }
    if (variable.table) {
      return tableBroker.getTable(variable.table).pipe(mergeMap(table => {
        const oldView = this._oldViews.get(variable.table!);
        if (oldView)
          oldView.delete();

        let subject = this._tableSubjects.get(variable.table!);
        if (!subject) {
          subject = new Subject();
          this._tableSubjects.set(variable.table!, subject);
        }

        async function GetData() {
          const view = await table.view();
          view.on_update(async () => {
            subject!.next(await view.to_json());
          });
          subject!.next(await view.to_json());
        }

        return subject;
      }));
    }
    if (typeof variable.value !== 'undefined') {
      return of(variable);
    }
    log(`Variable not resolved: ${variable.name}`, variable);
    throw new Error('Variable not resolved');
  }

  private async _renderLiquid([template, variables]: [Template[], any]) {
    log('rendering liquid', template, variables);

    const model = {} as any
    variables.forEach((i:any) => {model[i.name] = i.value;});
    const oldRendered = this.rendered;
    console.log('rendering liquid', template, model);
    this.rendered = await liquid.render(template, model);
    this._data$.next(model);
    this.requestUpdate('rendered', oldRendered);
  }
  render() {
    log(`${this.id} ${this.show} - render`, this.rendered);
    const slot = html`
      <slot style="display: none;" name="liquid" @slotchange="${this._onSlotChange}"></slot>`;
    const variablesSlot = html`
      <slot style="display: none;" name="variables" class="hide" @slotchange="${this._onVariablesSlotChange}"></slot>`;
    const scriptSlot = html`
      <slot style="display: none;" name="script" class="hide" @slotchange="${this._onScriptsSlotChange}"></slot>`;
    const show = (this.show || pdqHotkeyService.showPdqData.currentValue());

    return html`
      ${slot}
      ${variablesSlot}
      ${scriptSlot}
      ${!!this.error ? html`
        <cds-inline-notification kind="error" title="Liquid Template"
                                 subtitle="${this.error} ${typeof this.error}"></cds-inline-notification>` : nothing}
      ${show ? html`
        <div class="col show ${this.error ? 'error' : ''}">
          ${this.renderToolBar(html`
            <cds-icon-button slot="tool-bar" @click="${this.save}" kind="primary">
              <span slot="tooltip-content">Save</span>
              ${Save({ slot: 'icon' })}
            </cds-icon-button>
          `)}
          <div class="m-2">
            <pdq-liquid-variable-stack
              @pdq-liquid-variables-saved="${this._variablesChanged}"
              .variables="${this.variables}"
            >
            </pdq-liquid-variable-stack>
            <cds-textarea class="template" value="${this.template?.trim()}"><label slot="label-text">Liquid
              Template:</label></cds-textarea>
          </div>
        </div>
        <hr>
      ` : nothing
      }${unsafeHTML(this.rendered)}`;
  }
  async _variablesChanged(evt: CustomEvent) {
    log('variablesChanged', evt.detail);
    this._variables$.next(evt.detail);
  }
  private async _onSlotChange(event: Event) {
    log(`${this.id} - slot change`);
    const slot = event.target as HTMLSlotElement;
    const nodes = slot.assignedNodes({ flatten: true });
    const template = extractTextFromScripts(nodes);
    log(`template: ${template}`);
    this._template$.next(template.replace(/xscript/g, 'script'));
    this.requestUpdate('template');
  }
  private async _onScriptsSlotChange(event: Event) {
    log(`${this.id} - slot change`);
    const slot = event.target as HTMLSlotElement;
    const nodes = slot.assignedNodes({ flatten: true });
    this._script = extractTextFromScripts(nodes);
    this.requestUpdate();
  }
  private async _onVariablesSlotChange(event: Event) {
    log(`${this.id} - variables slot change`);
    const slot = event.target as HTMLSlotElement;
    const nodes = slot.assignedNodes({ flatten: true });
    const template = extractTextFromScripts(nodes);
    try {
      const vars = JSON.parse(template);
      if (Array.isArray(vars)) {
        log('variables switching to', vars);
        this._variables$.next(vars);
      } else {
        log('variables not an array... ignoring...', vars);
      }
    } catch (e: any) {
      log(`Error parsing JSON: \n${e?.message || e}`);
      notificationService.error(`pdq-liquid#${this.id}|Error parsing JSON: \n${e?.message || e}`);
    }
  }

  scriptAbortController = new AbortController();
  signal = this.scriptAbortController.signal;
  _firstScript = true;
  updated = async () => {

    if (this._script !== '') {
      // todo - show this be split and triggered by rendered or liquid rendered?
      const meta = await blobRegistry.registerStringAsBlob(this._script, "application/javascript")
      let module;
      try {
        /* parceljs override import */
        module = await import(meta.url);
        /* parceljs override import */
      } catch(e) {
        log(`WARNING: failed to import script: ${e}`)
        console.log(`WARNING: failed to import script: ${e}`, this._script, e)
        return
      }

      if ((meta.added || this._firstScript) && module.init) {
        log('script found for the first time... calling init...')
        try {
          module.init.call(this, this)
        } catch (e) {
          log(`WARNING: init script failed: ${e}`)
          console.log(`WARNING: init script failed: ${e}`)
        }
      } else if (!module.init) {
        log(`INFO: no init function exported from script`)
      }
      if (module.rendered) {
        try {
          this.scriptAbortController.abort();
          this.scriptAbortController = new AbortController();
          this.signal = this.scriptAbortController.signal;
          module.rendered.call(this, this)
        }catch(e){
          log(`WARNING: rendered script failed: ${e}`)
          console.log(`WARNING: rendered script failed: ${e}`)
        }
      } else {
        log(`WARNING: no rendered function exported from script`)
      }
      this._firstScript = false
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this._template$.complete();
    this._variables$.complete();
    this._oldViews.forEach(i => i.delete());
    this._tableSubjects.forEach(i => i.complete());
    this._dataSubjects.forEach(i => i.complete());
    this._subscription.unsubscribe()
  }

}
