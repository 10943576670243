/**
 * @license
 *
 * Copyright IBM Corp. 2020, 2023
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 */

/**
 * Overflow menu size.
 */
export enum OVERFLOW_MENU_SIZE {
  /**
   * Small size.
   */
  SMALL = 'sm',

  /**
   * Medium size.
   */
  MEDIUM = 'md',

  /**
   * Large size.
   */
  LARGE = 'lg',
}

export const NAVIGATION_DIRECTION = {
  ArrowDown: 1,
  ArrowUp: -1,
};
