import { html, LitElement, nothing, PropertyValues } from 'lit';
import {customElement, property, query, state} from 'lit/decorators';

import Debug from 'debug';
import { extractTextFromScripts, getSha256 } from '../utils';
import { BlobMeta, blobRegistry } from '../services/BlobRegistry';
import { tableBroker } from '../services/TableBroker';
import { dataBroker, DataBroker } from '../services/DataBroker';
import { notificationService } from '../services/notificationService';


const log = Debug('pdq:flow:pdq-transform');
@customElement('pdq-transform')
export class PdqTransform extends LitElement {
    static styles = []
    @property({type: String})
    id: string = ''

    @property({type: Array})
    sources: string[] = []

    @state() script: {meta: BlobMeta, module: any} | undefined

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);
        this.sync();
    }
    protected updated(_changedProperties: PropertyValues) {
        super.updated(_changedProperties);
        this.sync();
    }

    cacheKey = ''
    async sync() {
        if ((this.sources?.length || 0) < 1 || !this.script || !this.id) {
            log('name, sources or module not set, sync not required')
            return
        }
        const nKey = await getSha256(this.sources.join('') + this.script.meta.hash)
        if (this.cacheKey == nKey) {
            log('sync not required')
            return
        }
        this.cacheKey = nKey
        log('cacheKey changed, syncing')
        if (!this.script.module.transform) {
            log('script does not export a transform function... sync aborted.')
            return
        }

        const sources = new Set<string>(this.sources)
        if (sources.has(this.id)){
            log('sources includes self... sync aborted.')
            return
        }
        log('adding derivation');
        try {
            await dataBroker.upsertDerivation(this.id, [...sources], this.script.module.transform)
        } catch (e) {
            log(`failed to add derivation: ${e}`)
            notificationService.warning(`failed to add derivation ${this.id}: ${e}`)
        }
    }


    render()    {
        return html`<slot style="display: none;" name="transform" @slotchange="${this._onSlotChange}"></slot>`
    }
    _onSlotChange = async (event: Event)=> {
        log(`transform script changed`);
        const slot = event.target as HTMLSlotElement;
        const nodes = slot.assignedNodes({ flatten: true });
        const script = extractTextFromScripts(nodes);

        if (!script?.trim()) {
            log(`no script found in slot`)
            return
        }
        const meta = await blobRegistry.registerStringAsBlob(script, "application/javascript")
        let module;
        try {
            /* parceljs override import */
            this.script = {
                meta,
                module: await import(meta.url)
            }
            /* parceljs override import */
        } catch(e) {
            log(`WARNING: failed to import script: ${e}`)
            console.log(`WARNING: failed to import script: ${e}`, script, e)
            return
        }
    }

}
