import { first, ReplaySubject } from '../observable/ReplaySubject';
import { googleAuth, GoogleAuth } from './googleAuth';
import { defer } from '../utils';
import { GAPI } from './interfaces';
import Debug from 'debug';
const GOOGLE_GIS_URI = 'https://apis.google.com/js/api.js';
let __gapi: Promise<GAPI> | undefined = undefined;

const log = Debug('pdq:google:GoogleApi');


export class GoogleApi {
  loaded = new ReplaySubject<boolean>(1);

  constructor(private googleAuth: GoogleAuth) { }

  private async getGAPI(): Promise<GAPI> {
    if (__gapi)
      return __gapi;

    const gapiSoon = defer<GAPI>();
    __gapi = gapiSoon.promise;
    const script = document.createElement('script');
    script.src = GOOGLE_GIS_URI;
    script.async = true;
    script.defer = true;
    script.onload = async () => {
      const gapi = (window as any).gapi;
      const d = defer()
      gapi.load('client',  d.resolve)
      await d.promise;

      //;(window as any).gapi;
      gapiSoon.resolve(gapi);
      log('google api init complete')
    };
    script.onerror = gapiSoon.reject;
    document.head.appendChild(script);
    await gapiSoon.promise;
    return __gapi;
  }

  async load(services: {service: string, version: string}[]) {
    log('loading discovery apis')
    this.loaded.next(false);
    const api = await this.getGAPI();

    const promises = Promise.all(services.map(i => {
      const d = defer()
      api.client.load(i.service, i.version, d.resolve)
      return d.promise
    }))
    await promises;
    this.loaded.next(true);
    log('loaded api discovery')
  }


  async listFiles(driveId: string | undefined = undefined, parentId: string | undefined = undefined) {

    const gapi = await this.getGAPI();
    let pageToken: string | undefined;
    let allFiles: any[] = [];

    const fetchFiles = async () => {
      const queryParams: any = {
        'pageSize': 100,
        'fields': 'nextPageToken, files(*)',
        'corpora': driveId ? 'drive' : 'user',
        'includeItemsFromAllDrives': !!driveId,
        'supportsAllDrives': !!driveId,
        'pageToken': pageToken
      };

      if (driveId) {
        queryParams['driveId'] = driveId;
      }
      if (parentId) {
        queryParams['q'] = `'${parentId}' in parents`;
      }

      const response = await gapi.client.drive.files.list(queryParams);
      allFiles = allFiles.concat(response.result.files);
      pageToken = response.result.nextPageToken;

      if (pageToken) {
        await fetchFiles();
      }
    };

    if (!this.loaded.currentValue()) {
      log('list files... waiting for loaded')
      await first(this.loaded, x => x);
    }
    log('getting files...')
    await this.googleAuth.setToken(gapi);
    await fetchFiles();
    return allFiles
  }

  async listDrives() {
    const gapi = await this.getGAPI();
    let pageToken: string | undefined;
    let allDrives: any[] = [];

    const fetchDrives = async () => {
      const response = await gapi.client.drive.drives.list({
        'pageSize': 100,
        'fields': 'nextPageToken, drives(*)',
        'pageToken': pageToken
      });

      allDrives = allDrives.concat(response.result.drives);
      pageToken = response.result.nextPageToken;

      if (pageToken) {
        await fetchDrives();
      }
    };

    log(`getting list of drives...`)
    await this.googleAuth.setToken(gapi);
    await fetchDrives();
    return allDrives;
  }

  async getFileContent(fileId: string, alt = 'media'): Promise<Blob|string> {
    const gapi = await this.getGAPI();
    if (!this.loaded.currentValue()) {
      log('getFileContent: waiting for loaded')
      await first(this.loaded, x => x);
    }
    try {
      log(`getting file ${fileId}`)
      await this.googleAuth.setToken(gapi);

      const response = await gapi.client.drive.files.get({
        fileId: fileId,
        alt: alt
      });

      log('got response...', response.status)
      if (alt == 'media') {
        const arr = this.stringToArrayBuffer(response.body as string);
        return new Blob([arr], { type: (response.headers['Content-Type'] || 'application/octet-stream') });
      } else {
        return response.Body
      }
    }catch (e) {
      log('error getting file content', e)
      throw e
    }
  }

  async uploadDriveFile(
    driveId: string | undefined,
    parentFolderId: string | undefined,
    file: Blob,
    fileId: string | undefined = undefined,
    properties = {}
  ) {
    const gapi = await this.getGAPI();

    const boundary = '-------314159265358979323846';
    const delimiter = '\r\n--' + boundary + '\r\n';
    const close_delim = '\r\n--' + boundary + '--';

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        const contentType = file.type || 'application/octet-stream';
        const metadata = {
          'name': file.name,
          'mimeType': contentType,
          'parents': parentFolderId && !fileId ? [parentFolderId] : [],
          'properties': properties
        };

        const base64Data = btoa(reader.result as string);
        const multipartRequestBody =
          delimiter +
          'Content-Type: application/json\r\n\r\n' +
          JSON.stringify(metadata) +
          delimiter +
          'Content-Type: ' + contentType + '\r\n' +
          'Content-Transfer-Encoding: base64\r\n' +
          '\r\n' +
          base64Data +
          close_delim;

        let path;
        let method;
        if (fileId) {
          path = `/upload/drive/v3/files/${fileId}?uploadType=multipart`;
          method = 'PATCH'; // Use PATCH to update the existing file
        } else {
          path = driveId ? `/upload/drive/v3/files?driveId=${driveId}&uploadType=multipart` : '/upload/drive/v3/files?uploadType=multipart';
          method = 'POST';
        }

        try {
          log(`sending request [${method}]${path} ...`)
          await this.googleAuth.setToken(gapi);
          const response = await gapi.client.request({
            'path': path,
            'method': method,
            'headers': {
              'Content-Type': 'multipart/related; boundary="' + boundary + '"'
            },
            'body': multipartRequestBody
          });
          resolve(response.result);
        } catch (error) {
          reject(error);
        }
      };
    });
  }

  async getSheetRange(spreadsheetId: string, range: string) {
    const gapi = await this.getGAPI();
    log(`getting sheet range ${spreadsheetId} ${range}...`)
    await this.googleAuth.setToken(gapi);
    const response = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: spreadsheetId,
      range: range
    });
    return response.result;
  }

  async writeSheetRange(spreadsheetId: string, range: string, values: string[][]) {
    const gapi = await this.getGAPI();
    log(`writing sheet range ${spreadsheetId} ${range}...`)
    await this.googleAuth.setToken(gapi);
    const response = await gapi.client.sheets.spreadsheets.values.append({
      spreadsheetId: spreadsheetId,
      range: range,
      valueInputOption: 'RAW',
      insertDataOption: 'INSERT_ROWS',
      resource: {
        values: values
      }
    });
    return response.result;
  }

  private stringToArrayBuffer(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
}

export const googleApi = new GoogleApi(googleAuth);
