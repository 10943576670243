import {customElement, property, state} from "lit/decorators";
import {html, LitElement, nothing} from "lit";

import '@carbon/web-components/es/components/copy-button/index.js';
import '@carbon/web-components/es/components/icon-button/index.js';
import '@carbon/web-components/es/components/textarea/index.js';
import '@carbon/web-components/es/components/text-input/index.js';
import '@carbon/web-components/es/components/select/';
import Icon from '@carbon/web-components/es/icons/fetch-upload/16.js';
import SaveIcon from '@carbon/web-components/es/icons/save/16.js';
import ArrowRightIcon from '@carbon/web-components/es/icons/arrow--right/16.js';
import RefreshIcon from '@carbon/web-components/es/icons/restart/16.js';

import Debug from "debug";
import {col, grow, hide, margin, padding, row} from "../styles";
import pdqHotkeyService from "../services/PdqHotkeyService";
import {PdqDiscoverable, pdqDiscoveryLayer} from "./_pdq-mixin";
import {TAG_TYPE} from "@carbon/web-components/es/components/tag/defs";
import {dataClient, CacheStrategies, DataProcessors, DataResponseMeta} from "../services/DataClient";
import type {CacheStrategy,DataProcessor} from "../services/DataClient";
import { dataBroker } from '../services/DataBroker';
import { defer } from '../utils';

const tagName = 'pdq-data-request'
const log = Debug(`pdq:flow:${tagName}`);

@customElement(tagName)
@pdqDiscoveryLayer(log, Icon, tagName, 'rgba(255, 236, 83, 0.13)', TAG_TYPE.TEAL)
export class PdqDataRequest extends LitElement implements PdqDiscoverable{
    static styles = [hide, row, col, grow, padding, margin]
    @property() show: boolean = false;
    @state() dataPreview = 'no data';

    @property({type: String}) url: string = '';
    @property({type: String}) strategy: CacheStrategy = CacheStrategies.useStale;
    @property({ attribute: 'data-type' , type: String }) dataType: DataProcessor = DataProcessors.json;
    @property({ attribute: 'fetch-options', type: Object}) fetchOptions: any  = {};
    @property({type: String}) index: string | undefined = undefined;
    @property({type: String}) jmespath: string | undefined = undefined;
    @property({type: String}) error: string | undefined = 'No url provided';

    @state() loading: boolean = false;
    @state() data: any = undefined;
    @state() dirty: boolean = false;
    @state() newState: any|undefined;

    constructor() {
        super();
    }

    render() {
        // log(`pdq-data-request: ${this.id} ${this.show} - render`, this.dataPreview)
        if (this.show || pdqHotkeyService.showPdqData.currentValue()) {
            const toolbar = html`
                    <div class="row center ml-1 mr-1" style="height: 100%">
                        ${this.dirty ? html`
                            <cds-tag type="${TAG_TYPE.TEAL}">
                              Click save to update the data source ${ArrowRightIcon()}
                            </cds-tag>`: nothing}
                        <cds-icon-button slot="tool-bar" @click="${this.save}" kind="primary" ?disabled="${!this.dirty}" >
                            <span slot="tooltip-content"> Save </span>
                            ${SaveIcon({slot: 'icon'})}
                        </cds-icon-button>
                        <cds-icon-button slot="tool-bar" @click="${this.refresh}" kind="primary" >
                            <span slot="tooltip-content"> Refresh </span>
                            ${RefreshIcon({slot: 'icon'})}
                        </cds-icon-button>
                    </div>
                `;
            return html`
                <div class="show col ${this.error ? 'error' : ''}">
                    ${this.renderToolBar(toolbar)}
                    ${this.loading ? html`
                        <cds-inline-loading class="m-2" status="active">Loading data...</cds-inline-loading>` : nothing}

                    <div class="m-2">
                        <cds-text-input
                                label="Url"
                                value="${this.url}"
                                @onChange="${this.onUrlChange}"
                        >
                        </cds-text-input>
    
                        <cds-select label-text="Cache Strategy" 
                                    @change="${this.onStrategyChange}" 
                                    value="${this.strategy}">
                                ${Object.entries(CacheStrategies).map(([key, value]) => html`
                                    <cds-select-item  value="${value}">${key}</cds-select-item>
                                `)}
                        </cds-select>
    
                        <cds-select label-text="Data Type" 
                                    @cds-select-selected="${this.onProcessorChange}" 
                                    value="${this.dataType}">
                                ${Object.entries(DataProcessors).map(([key, value]) => html`
                                    <cds-select-item value="${value}">${key}</cds-select-item>
                                `)}
                        </cds-select>
    
    
                        <cds-textarea value="${this.dataPreview}">
                            <label slot="label-text">Html:</label>
                        </cds-textarea>
                    </div>
                </div>
            `;
        }
        return nothing
    }
    async refresh(obayStrategy=false) {
        log('refresh')
        let fetchOptions = undefined;
        if (this.fetchOptions){
            if (typeof(this.fetchOptions) === 'string')
                fetchOptions = JSON.parse(this.fetchOptions)
            else
                fetchOptions = this.fetchOptions
        }
        const resp = await dataClient.get(this.url,
          obayStrategy ? this.strategy : CacheStrategies.neverCache, this.dataType, fetchOptions);

        const d = defer();
        const unsubs = [
            resp.loading.subscribe(i => {
              log(`refresh - loading: ${i}`)
            }),
            resp.error.subscribe(i => {
              if (!i)
                return
              log(`refresh - error: ${i}`)
              d.reject(i)
            }),
            resp.data.subscribe(async i => {
              log(`refresh - data received: ${i}`)
              try {
                await dataBroker.setDataSource(this.id, i);
                d.resolve(i)
              } catch(e) {
                log(`refresh - failed`, e)
                d.reject(e)
              }
            })
        ];
        await d.promise
        unsubs.forEach(u => u())
        log('refresh - complete')
    }
    private save(){
        this.url = this.newState.url || this.url;
        this.strategy = this.newState.strategy || this.strategy;
        this.dataType = this.newState.dataType || this.dataType;
        this.dirty = false;
    }
    private onUrlChange(event: InputEvent){
        this.dirty = true;
        this.newState = {
            ...this.newState,
            url: (event.target as any)?.value
        }
    }
    private onStrategyChange(event: InputEvent){
        this.dirty = true;
        this.newState = {
            ...this.newState,
            strategy: (event.target as any)?.value
        }
    }
    private onProcessorChange(event: InputEvent){
        this.dirty = true;
        this.newState = {
            ...this.newState,
            dataType: (event.target as any)?.value
        }
    }
    private _changeKey = 'inital-value'
    async willUpdate(){
        const changeKey = `${this.url}|${this.strategy}|${this.dataType}|${this.index}`
        log(`pdq-data-request[${this.id}] (${this._changeKey === changeKey}) ${changeKey}`)
        if (this._changeKey === changeKey) {
            return
        }
        this._changeKey = changeKey
        if (!this.url) {
            this.error = 'no url provided';
            this.loading = false;
            this.data = undefined
            return
        }

        this.dataPreview = `
<pdq-data-request 
    id="${this.id}" 
    url="${this.url}"
    strategy="${this.strategy}"
    data-type="${this.dataType}"
    ${this.fetchOptions ? `\n\tfetch-options='${JSON.stringify(this.fetchOptions)}'` : '' }${this.jmespath ? `\n\tjmespath='${this.jmespath}'` : '' }
></pdq-data-request>
        `;

        this.refresh(true)
    }
}